import React, { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';

import "./styles/InputStyles.css"
export default function ReportSendAlert({ open, handleClose }) {


    return (
        <div className='requirementAlertWrapper'>
            <Alert className='requiremetAlertComponent' open={open} severity="success" onClose={handleClose}>
                <Typography>An email containing a <span style={{ fontWeight: "600" }}>pdf summary</span> of your report will sent in the next few minutes. </Typography>
            </Alert>

        </div>
    );
}