import React, { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Typography, IconButton } from '@mui/material';

import "../AccordianStyles.css"
export default function PopoverComponent({ infoText }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton
                className='myIconButtonClass'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
                <InfoOutlinedIcon />
            </IconButton>

            <Popover

                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography style={{ margin: "5px" }}>{infoText}</Typography>
            </Popover>
        </div>
    )
}
