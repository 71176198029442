import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Styles
import "../AccordianStyles.css";

// Components
import HeaderandInputs from "../InputComponents.js/HeaderandInputs";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import { VolumeUnits } from "../../Utilities/units";

export default function FuelConsumptionTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL) (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)

  const plantLabels = [
    [
      "plantVol",
      "Plant Volume  / month",
      VolumeUnits,
      "Enter the total volume of that is consumed at the facility during production and processing. Select units from m3 (Metres Cubed), gal (gallons), BBL (barrels), L (Litres), BOE (Barrels of Oil Equivalent), MCF (Thousands of Cubic Feet), MMCF (Millions of Cubic Feet).",
    ],
    [
      "fuelWellAllocation",
      "Fuel Well Allocation (%)",
      [],
      "Estimated pro rata percentage allocation of the fuel to the well.",
    ],
    [
      "lhv",
      "LHV (MJ/m3)",
      [],
      "Lower Heating Value (LHV) of Natural Gas is the total heat obtained from combustion less the latent heat of vaporization of the water in the fuel.",
    ],
  ];

  // Hooks and States

  const [plant, setPlant] = useState({
    plant: {
      plantVol: { value: "", unit: VolumeUnits.default },
      allocation: { value: "10", unit: "" },
      lhv: { value: "34.6", unit: "" },
    },
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const plantVolObj = populatedUWI.fuelConsumptionPerMonth;
      setPlant({
        plant: {
          plantVol: {
            value: plantVolObj.plantVol,
            unit: plantVolObj.plantVolUnit,
          },
          allocation: { value: plantVolObj.allocation, unit: "" },
          lhv: { value: plantVolObj.LHVMJ, unit: "" },
        },
      });
    }
  }, [populatedUWI]);

  return {
    plant,
    renderFuelConsumption: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Fuel Consumption</Typography>
              <div>
                <PopoverComponent
                  infoText={
                    "This section focuses on source and type of production coming from the well."
                  }
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <HeaderandInputs
                    header=""
                    labels={plantLabels}
                    inputs={plant}
                    valueChange={setPlant}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
