import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Styles
import "../AccordianStyles.css";

// Components
import HeaderandInputs from "../InputComponents.js/HeaderandInputs";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import { VolumeUnits, FlareGasUnits } from "../../Utilities/units";

export default function FlareVentTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)
  const flareVentLabels = [
    [
      "efficiency",
      "Combustion Efficiency (%)",
      [],
      "The Combustion Efficiency of a flare can be from 80-99% depending on the type of combustion.",
    ],
    [
      "ch4MoleFraction",
      "CH4 mole fraction (%)",
      [],
      "Natural Gas is 94.7% methane at standard conditions. Specific to facility combustion calculations.",
    ],
    [
      "co2MoleFraction",
      "CO2 mole Fraction (%)",
      [],
      "CO2 is carbon dioxide as a percentage of natural gas at standard conditions.",
    ],
  ];

  const plantVolLabels = [
    [
      "plantVolume",
      "Facility Volume / month",
      VolumeUnits,
      "Enter the total volume of Flare and Vent that occurs at the facility during production and processing. Select units from m3 (Metres Cubed), gal (gallons), BBL (barrels), L (Litres), BOE (Barrels of Oil Equivalent), MCF (Thousands of Cubic Feet), MMCF (Millions of Cubic Feet).",
    ],
    [
      "flareAllocation",
      "Flare/Vent Well Allocation (%)",
      FlareGasUnits,
      "Estimated pro rata percentage allocation of the flare and vent to the well.",
    ],
  ];

  const [plantVol, setPlantVol] = useState({
    plantVol: {
      plantVolume: { value: "", unit: VolumeUnits.default },
      flareAllocation: { value: "10", unit: FlareGasUnits.default },
    },
  });

  const [flareVent, setFlareVent] = useState({
    flareVent: {
      efficiency: { value: "98" },
      ch4MoleFraction: { value: "94.7", unit: "" },
      co2MoleFraction: { value: "2", unit: "" },
    },
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const flareObject = populatedUWI.flareVentPerMonth;
      setPlantVol({
        plantVol: {
          plantVolume: {
            value: flareObject.plantVol,
            unit: flareObject.plantVolUnit,
          },
          flareAllocation: {
            value: flareObject.allocation,
            unit: flareObject.flareGasType,
          },
        },
      });

      setFlareVent({
        flareVent: {
          efficiency: { value: flareObject.efficiency },
          ch4MoleFraction: { value: flareObject.CH4Mol, unit: "" },
          co2MoleFraction: { value: flareObject.CO2Mol, unit: "" },
        },
      });
    }
  }, [populatedUWI]);

  return {
    flareVent,
    plantVol,
    renderFlareVent: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Flare/Vent </Typography>
              <div>
                <PopoverComponent
                  infoText={
                    "Flaring is controlled burning of gas that takes place during production and processing. Venting is a controlled release of gases into the atmosphere during production."
                  }
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              <Grid item md={12}>
                <HeaderandInputs
                  labels={plantVolLabels}
                  inputs={plantVol}
                  valueChange={setPlantVol}
                />
              </Grid>
              <Grid item md={12}>
                <HeaderandInputs
                  labels={flareVentLabels}
                  inputs={flareVent}
                  valueChange={setFlareVent}
                />
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
