import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../AccordianStyles.css";

// Components
import InternalTab from "../InputComponents.js/InternalTabComponent";
import HeaderandInputs from "../InputComponents.js/HeaderandInputs";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import {
  PressureUnits,
  OilVolumeUnits,
  OilVolumePerHourUnits,
  TemperatureUnits,
  LengthUnits,
  SelectUnits,
} from "../../Utilities/units";

export default function EmissionSourceTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)

  const tankVentingEmissionLabels = [
    [
      "numberOfTanks",
      "Number of Tanks",
      [],
      "Enter the number of tanks where the production is stored.",
    ],
    ["gaugePressureTest", "Pg @ test", PressureUnits, "Pressure at Test."],
    ["gaugePressureTank", "Pg @ tank", PressureUnits, "Pressure at Tank."],
    [
      "volumeLiquid",
      "Volume Liquid m3 / month",
      OilVolumeUnits,
      "Volume of Oil Throughput per month.",
    ],
  ];

  const hydrocarbonLiquidLossesLabels = [
    [
      "numberOfTrucks",
      "Number of Rail/Tank Trucks",
      [],
      "Enter the number of trucks and/or rail cars required to transport oil and liquids to and from the facility.",
    ],
    ["rvp", "RVP", [], "Reid vapour pressure of liquid."],
    ["sf", "SF", [], "Saturation Factor [dimensionless]."],
    ["oilVolume", "Oil Volume m3 / month", OilVolumeUnits],
    [
      "tt",
      "Tt",
      TemperatureUnits,
      "Enter the average temperature of oil. Select units of K (Kelvin), C (Celcius), F (Fahrenheit).",
    ],
  ];

  const solidDesiccantDehydratorsLabels = [
    [
      "numberOfDehydrators",
      "Number of Dehydrators",
      [],
      "Enter the number of Dehydrators at the facility.",
    ],
    [
      "h",
      "H",
      LengthUnits,
      "Enter the height of the Dehydrators at the facility. Select the units from m (metres), km (Kilometers), cm (centimetres), fee, inches.",
    ],
    [
      "d",
      "D",
      LengthUnits,
      "Enter the depth of the Dehydrators at the facility. Select the units from m (metres), km (Kilometers), cm (centimetres), fee, inches.",
    ],
    ["p2", "P2", PressureUnits, "Pressure of the Gas (Gauge)"],
    [
      "fractionFill",
      "Fraction Fill",
      [],
      "Fraction of the vessel that is filled with gas (dimensionless).",
    ],
    [
      "frequency",
      "Frequency / month",
      [],
      "Frequency of refilling (cycles per month).",
    ],
  ];

  const pigTrapOpeningsAndPurgesLabels = [
    [
      "numberOfPigTraps",
      "Number of Pig Traps",
      [],
      'A pig trap is a launching station to send a "pig" down a section of a pipeline.',
    ],
    [
      "vv",
      "Vv",
      OilVolumeUnits,
      "Total physical volume of equipment chambers between isolation valves being depressurized (including pig traps, pipes, compressors, and vessels).",
    ],
    [
      "ta",
      "Ta",
      { default: TemperatureUnits.units[1], units: TemperatureUnits.units },
      "Temperature of gas.",
    ],
    [
      "pa1",
      "Pa, 1",
      PressureUnits,
      "Enter the pressure of the pipeline. Select your units, default is kPa (kilopascal).",
    ],
    ["pa2", "Pa, 2", PressureUnits, "Gauge pressure after depressurizing."],
    [
      "n",
      "N / month",
      [],
      "Number of blowdown events for the subject month and vessel.",
    ],
  ];

  const engineOrTurbineStartsLabels = [
    [
      "numberOfEngineOrTurbineStart",
      "Number of engine or turbine starts per month",
      [],
      "Enter the number of times per month the facility operator starts the compressors or turbines.",
    ],
    ["er", "ER ", OilVolumePerHourUnits, "Emission rate for the device."],
    ["t", "t", [], "Amount of time starter is in use."],
    ["allocation", "Allocation (%)"],
  ];

  // Hooks and States
  const [tankVentingEmissions, setTankVentingEmissions] = useState({
    tankVentingEmissions: {
      numberOfTanks: { value: "" },
      gaugePressureTest: { value: "200", unit: PressureUnits.default },
      gaugePressureTank: { value: "", unit: PressureUnits.default },
      volumeLiquid: { value: "50", unit: OilVolumeUnits.default },
    },
  });

  const [hydrocarbonLiquidLosses, setHydroCarbonLiquidLosses] = useState({
    hydrocarbonLiquidLosses: {
      numberOfTrucks: { value: "" },
      rvp: { value: "40.5", unit: "" },
      sf: { value: "0.5", unit: "" },
      oilVolume: { value: "100", unit: OilVolumeUnits.default },
      tt: { value: "283.15", unit: TemperatureUnits.default },
    },
  });

  const [solidDesiccantDehydrators, setSolidDesiccantDehydrators] = useState({
    solidDesiccantDehydrators: {
      numberOfDehydrators: { value: "", unit: "" },
      h: { value: "8", unit: LengthUnits.default },
      d: { value: "2", unit: LengthUnits.default },
      p2: { value: "1200", unit: PressureUnits.default },
      fractionFill: { value: "0.7", unit: "" },
      frequency: { value: "1", unit: "" },
    },
  });

  const [pigTrapOpeningsAndPurges, setPigTrapOpeningsAndPurges] = useState({
    pigTrapOpeningsAndPurges: {
      numberOfPigTraps: { value: "" },
      vv: { value: "3.693", unit: OilVolumeUnits.default },
      ta: { value: "10", unit: TemperatureUnits.units[1] },
      pa1: { value: "260", unit: PressureUnits.default },
      pa2: { value: "50", unit: PressureUnits.default },
      n: { value: "1", unit: "" },
    },
  });

  const [engineOrTurbineStarts, setEngineOrTurbineStarts] = useState({
    engineOrTurbineStarts: {
      numberOfEngineOrTurbineStart: { value: "", unit: "" },
      er: { value: "5172", unit: OilVolumePerHourUnits.default },
      t: { value: "0.05", unit: "" },
      allocation: { value: "10", unit: "" },
    },
  });

  const pneumaticInstrumentLabels = [
    [
      "numberOfLevelControllers",
      "Number of Level Controller",
      [],
      "Enter the number of Level Controllers used at the well or facility. A pneumatic level controller works with a control valve to provide liquid level control in oil and gas production.",
    ],
    [
      "levelControllerHours",
      "Level Controller Annual Operating Hours",
      [],
      " Enter the number of hours the pneumatic lever controller is in operation per month.",
    ],
    [
      "numberOfPositioners",
      "Number of Positioners",
      [],
      "A Pneumatic Valve Positioner receives a pneumatic signal from a controller and supplies the valve actuator with the correct air pressure to provide an accurate valve stem or shaft position.",
    ],
    [
      "positionerHours",
      "Positioner Annual Operating Hours",
      [],
      "Enter the number of hours the pneumatic positioner is in operation per month.",
    ],
    [
      "numberOfPressureControllers",
      "Number of Pressure Controller",
      [],
      "A pneumatic pressure controller is a mechanical device designed to measure temperature or pressure and transmit a corrective air signal to the final control element.",
    ],
    [
      "pressureControllerHours",
      "Pressure Controller Annual Operating Hours",
      [],
      "Enter the number of hours the pneumatic pressure controller is in operation per month.",
    ],
    [
      "numberOfTransducers",
      "Number of Transducer",
      [],
      "Pneumatic Transducers are used in pneumatic control systems to convert a proportional electric output signal from a controller into a direct-acting, proportional pneumatic signal.",
    ],
    [
      "transducerHours",
      "Transducer Annual Operating Hours",
      [],
      "Enter the number of hours the pneumatic transducer is in operation per month.",
    ],
    [
      "numberOfGenericInstruments",
      "Number of Generic Instrument",
      [],
      "Enter the number of other types of pneumatic devices used at the facility.",
    ],
    [
      "genericInstrumentHours",
      "Generic Instrument Annual Operating Hours",
      [],
      "Enter the number of hours the generic pneumatic device is in operation per month.",
    ],
  ];

  const pneumaticPumpLabels = [
    [
      "numberOfGenericPistonPump",
      "Number of Generic Piston Pump",
      [],
      "A Pneumatic piston pumps is equipped with an air motor that alternately moves a piston up and down.",
    ],
    [
      "genericPistonPumpHours",
      "Generic Piston Pump Annual Operating Hours",
      [],
      "Enter the number of hours the piston pump is in operation per month.",
    ],
    [
      "numberOfGenericDiaphragmPump",
      "Number of Generic Diaphragm Pump",
      [],
      "A diaphragm pump is a pneumatic pump that uses an air valve that directs compressed air between two sides of the pump, back and forth.",
    ],
    [
      "genericDiaphragmHours",
      "Generic Diaphragm Annual Operating Hours",
      [],
      "Enter the number of hours the diaphragm pump is in operation per month.",
    ],
  ];

  const compressorSealLabels = [
    [
      "numberOfCompressorSeal",
      "Number of Compressor Seal",
      SelectUnits,
      "Enter the number of compressor seals at the facility.",
    ],
    ["compressorSealHours", "Compressor Seal Annual Operating Hours"],
    ["wellPercentage", "% well"],
  ];
  const glycolDehydratorLabels = [
    ["numberOfGlycolDehydrators", "Number of Glycol Dehydrators", SelectUnits],
    ["wellAllocation", "Well Allocation %"],
  ];

  const [pneumaticInstruments, setPneumaticInstruments] = useState({
    pneumaticInstruments: {
      numberOfLevelControllers: { value: "", unit: "" },
      levelControllerHours: { value: "1700", unit: "" },
      numberOfPositioners: { value: "", unit: "" },
      positionerHours: { value: "1700", unit: "" },
      numberOfPressureControllers: { value: "", unit: "" },
      pressureControllerHours: { value: "1700", unit: "" },
      numberOfTransducers: { value: "", unit: "" },
      transducerHours: { value: "1700", unit: "" },
      numberOfGenericInstruments: { value: "", unit: "" },
      genericInstrumentHours: { value: "1700", unit: "" },
    },
  });

  const [pneumaticPumps, setPneumaticPumps] = useState({
    pneumaticPumps: {
      numberOfGenericPistonPump: { value: "" },
      genericPistonPumpHours: { value: "1700", unit: "" },
      numberOfGenericDiaphragmPump: { value: "", unit: "" },
      genericDiaphragmHours: { value: "1700", unit: "" },
    },
  });

  const [compressorSeals, setCompressorSeals] = useState({
    compressorSeals: {
      numberOfCompressorSeal: { value: "", unit: SelectUnits.default },
      compressorSealHours: { value: "8000", unit: "" },
      wellPercentage: { value: "10", unit: "" },
    },
  });

  const [glycolDehydrators, setGlycolDehydrators] = useState({
    glycolDehydrators: {
      numberOfGlycolDehydrators: { value: "", unit: SelectUnits.default },
      wellAllocation: { value: "10", unit: "" },
    },
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const tankObj = populatedUWI.emissionSourcesPerMonth.tankVenting;
      console.log(tankObj);
      setTankVentingEmissions({
        tankVentingEmissions: {
          numberOfTanks: { value: tankObj.numberOfTanks },
          gaugePressureTest: {
            value: tankObj.gaugePressureTest,
            unit: tankObj.gaugePressureTestUnit,
          },
          gaugePressureTank: {
            value: tankObj.gaugePressureTank,
            unit: tankObj.gaugePressureTankUnit,
          },
          volumeLiquid: {
            value: tankObj.volumeLiquid,
            unit: tankObj.volumeLiquidUnit,
          },
        },
      });

      // HydroCarbon
      const hydroObj =
        populatedUWI.emissionSourcesPerMonth.hydrocarbonLiquidLosses;
      setHydroCarbonLiquidLosses({
        hydrocarbonLiquidLosses: {
          numberOfTrucks: { value: hydroObj.numberOfRailOrTankTrucks },
          rvp: { value: hydroObj.rvp, unit: "" },
          sf: { value: hydroObj.sf, unit: "" },
          oilVolume: { value: hydroObj.oilVolume, unit: hydroObj.oilVolumUnit },
          tt: { value: hydroObj.tt, unit: hydroObj.ttUnit },
        },
      });

      // Solid Desiccant
      const solidObj =
        populatedUWI.emissionSourcesPerMonth.solidDesiccantDehydrators;
      setSolidDesiccantDehydrators({
        solidDesiccantDehydrators: {
          numberOfDehydrators: {
            value: solidObj.numberOfDehydrators,
            unit: "",
          },
          h: { value: solidObj.h, unit: solidObj.hUnit },
          d: { value: solidObj.d, unit: solidObj.dUnit },
          p2: { value: solidObj.p2, unit: solidObj.p2Unit },
          fractionFill: { value: solidObj.fractionFill, unit: "" },
          frequency: { value: solidObj.frequency, unit: "" },
        },
      });

      // Pig Traps
      const pigObj = populatedUWI.emissionSourcesPerMonth.pigTraps;
      setPigTrapOpeningsAndPurges({
        pigTrapOpeningsAndPurges: {
          numberOfPigTraps: { value: pigObj.numberOfPigTraps },
          vv: { value: pigObj.vv, unit: pigObj.vvUnit },
          ta: { value: pigObj.ta, unit: pigObj.taUnit },
          pa1: { value: pigObj.pa1, unit: pigObj.pa1Unit },
          pa2: { value: pigObj.pa2, unit: pigObj.pa2Unit },
          n: { value: pigObj.n, unit: "" },
        },
      });

      // Engine starts
      const engineObj =
        populatedUWI.emissionSourcesPerMonth.engineOrTurbineStarts;
      setEngineOrTurbineStarts({
        engineOrTurbineStarts: {
          numberOfEngineOrTurbineStart: {
            value: engineObj.numberOfEngineOrTurbineStart,
            unit: "",
          },
          er: { value: engineObj.er, unit: engineObj.erUnit },
          t: { value: engineObj.t, unit: "" },
          allocation: { value: engineObj.allocation, unit: "" },
        },
      });

      // Internal tabs
      const instrumentObjValues =
        populatedUWI.emissionSourcesPerMonth.pneumaticInstruments.number;
      const instrumentObjHours =
        populatedUWI.emissionSourcesPerMonth.pneumaticInstruments.hours;
      setPneumaticInstruments({
        pneumaticInstruments: {
          numberOfLevelControllers: {
            value: instrumentObjValues.levelControllers,
            unit: "",
          },
          levelControllerHours: {
            value: instrumentObjHours.levelControllers,
            unit: "",
          },
          numberOfPositioners: {
            value: instrumentObjValues.positioners,
            unit: "",
          },
          positionerHours: { value: instrumentObjHours.positioners, unit: "" },
          numberOfPressureControllers: {
            value: instrumentObjValues.pressureControllers,
            unit: "",
          },
          pressureControllerHours: {
            value: instrumentObjHours.pressureControllers,
            unit: "",
          },
          numberOfTransducers: {
            value: instrumentObjValues.transducers,
            unit: "",
          },
          transducerHours: { value: instrumentObjHours.transducers, unit: "" },
          numberOfGenericInstruments: {
            value: instrumentObjValues.genericInstruments,
            unit: "",
          },
          genericInstrumentHours: {
            value: instrumentObjHours.genericInstruments,
            unit: "",
          },
        },
      });

      const pumpObjValues =
        populatedUWI.emissionSourcesPerMonth.pneumaticPumps.number;
      const pumpObjHours =
        populatedUWI.emissionSourcesPerMonth.pneumaticPumps.hours;
      setPneumaticPumps({
        pneumaticPumps: {
          numberOfGenericPistonPump: { value: pumpObjValues.genericPistonPump },
          genericPistonPumpHours: {
            value: pumpObjHours.genericPistonPump,
            unit: "",
          },
          numberOfGenericDiaphragmPump: {
            value: pumpObjValues.genericDiaphragmPump,
            unit: "",
          },
          genericDiaphragmHours: {
            value: pumpObjHours.genericDiaphragmPump,
            unit: "",
          },
        },
      });

      const compressorObj = populatedUWI.emissionSourcesPerMonth.compressorSeal;
      setCompressorSeals({
        compressorSeals: {
          numberOfCompressorSeal: {
            value: compressorObj.numberOfSeals,
            unit: compressorObj.select,
          },
          compressorSealHours: {
            value: compressorObj.compressorSealHours,
            unit: "",
          },
          wellPercentage: { value: compressorObj.wellAllocation, unit: "" },
        },
      });

      const glycolObj = populatedUWI.emissionSourcesPerMonth.glycolDehydrators;
      setGlycolDehydrators({
        glycolDehydrators: {
          numberOfGlycolDehydrators: {
            value: glycolObj.numberOfDehydrators,
            unit: glycolObj.select,
          },
          wellAllocation: { value: glycolObj.wellAllocation, unit: "" },
        },
      });
    }
  }, [populatedUWI]);

  return {
    tankVentingEmissions,
    hydrocarbonLiquidLosses,
    solidDesiccantDehydrators,
    pigTrapOpeningsAndPurges,
    engineOrTurbineStarts,
    pneumaticInstruments,
    pneumaticPumps,
    compressorSeals,
    glycolDehydrators,
    renderEmission: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Other Emissions Sources</Typography>
              <div>
                <PopoverComponent
                  infoText={
                    "Other emissions sources that can originate at wells and facilities from connected equipment or processes on-site."
                  }
                />
              </div>
            </div>
          </AccordionSummary>
          {/* -------------- Inputs ------------- */}
          <AccordionDetails className="accordianDetails">
            <div className="allInputs">
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Tank Venting Emissions"
                    headerInfoIconText="Tank vent emissions occur when the pressurized liquid solution enters the atmospheric storage tank, gases are released from the solution."
                    labels={tankVentingEmissionLabels}
                    inputs={tankVentingEmissions}
                    valueChange={setTankVentingEmissions}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Hydrocarbon Liquid Losses"
                    headerInfoIconText={
                      "Liquid losses are types of emissions that result from hydrocarbon vapors that are released from the tank by expansion or contraction caused by changes in either temperature or pressure."
                    }
                    labels={hydrocarbonLiquidLossesLabels}
                    inputs={hydrocarbonLiquidLosses}
                    valueChange={setHydroCarbonLiquidLosses}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Solid Desiccant Dehydrators"
                    headerInfoIconText={
                      "Solid adsorbent or solid-desiccant dehydration processes absorbs water from natural gas by passing it through layers of solid material."
                    }
                    labels={solidDesiccantDehydratorsLabels}
                    inputs={solidDesiccantDehydrators}
                    valueChange={setSolidDesiccantDehydrators}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Pig Trap Openings and Purges"
                    headerInfoIconText={
                      'Pigging is process of inserting a "pig" into a pipeline clear out build-up and residue'
                    }
                    labels={pigTrapOpeningsAndPurgesLabels}
                    inputs={pigTrapOpeningsAndPurges}
                    valueChange={setPigTrapOpeningsAndPurges}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Engine or Turbine Starts"
                    labels={engineOrTurbineStartsLabels}
                    inputs={engineOrTurbineStarts}
                    valueChange={setEngineOrTurbineStarts}
                    gridSize={6}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <InternalTab
                    accordianTitle={["Pneumatic Instruments"]}
                    infoText="Pneumatic instruments are powered by gas pressure. They are mainly used where electrical power is not available. "
                    labels={[pneumaticInstrumentLabels]}
                    inputs={[pneumaticInstruments]}
                    valueChange={[setPneumaticInstruments]}
                    gridSize={6}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <InternalTab
                    accordianTitle={["Pneumatic Pumps"]}
                    infoText="A pneumatic pump uses pressurized gas or air to move a piston or a diaphragm in an industrial process."
                    labels={[pneumaticPumpLabels]}
                    inputs={[pneumaticPumps]}
                    valueChange={[setPneumaticPumps]}
                    gridSize={6}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <InternalTab
                    accordianTitle={["Compressor Seals"]}
                    infoText="Compressor process seals are designed to seal the rotating assembly in the stationary compressor case to prevent the process gas in the compressor from getting to the atmosphere and to prevent the atmosphere from getting into the process gas."
                    labels={[compressorSealLabels]}
                    inputs={[compressorSeals]}
                    valueChange={[setCompressorSeals]}
                    gridSize={6}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <InternalTab
                    accordianTitle={["Glycol Dehydrators"]}
                    infoText="Glycol dehydration processes absorbs water from natural gas by passing it through liquid desiccant."
                    labels={[glycolDehydratorLabels]}
                    inputs={[glycolDehydrators]}
                    valueChange={[setGlycolDehydrators]}
                    gridSize={6}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
