import React from "react";
import NumberInput from "./NumberInput";
import { Grid, Typography } from "@mui/material";

import "./styles/InputStyles.css";

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, ...items) {
  this.splice(index, 0, ...items);
};

export default function HeaderandInputsForArray({
  header,
  labels,
  inputs,
  valueChange,
  selectLabel,
  section,
  index,
}) {
  const handleTextChange = (id, whichValue, section, event) => {
    const newValue = event.target.value;
    const classIndex = section - 1;

    // Break JSON object into array of strings.
    const objects = JSON.stringify(whichValue).split(",");

    for (let i = classIndex * 14; i < (classIndex + 1) * 14; i++) {
      let objectStringName = "";
      let index = i;

      // String to compare to ID will differ depending if the object is the first item in array or not.
      if (index === 0) {
        objectStringName = objects[i].substring(
          objects[i].indexOf(":") + 4,
          objects[i].lastIndexOf("{") - 2
        );
      } else {
        if (id === "uwi") {
          objectStringName = objects[i].substring(
            2,
            objects[i].indexOf(":") - 1
          );
        } else {
          objectStringName = objects[i].substring(
            1,
            objects[i].indexOf(":") - 1
          );
        }
      }

      if (id.length === 1) {
        objectStringName = objects[i].substring(1, 2);
      }

      // Check if array item string is equal to the ID of text to change.
      if (objectStringName === id) {
        // If this is the first value to change.
        if (objects[i].charAt(objects[i].lastIndexOf(":") + 2) === '"') {
          const newStringValue = objects[i].replace(
            'value":""',
            'value":"' + newValue + '"'
          );

          objects[index] = newStringValue;
        }
        // Else If there is already a value in the input field
        else {
          // If the input is equal to the first item.
          // We will take out surrounding object name.
          if (index === 0) {
            const newStringValue = objects[i].replace(
              objects[i].substring(
                objects[i].lastIndexOf(":") + 2,
                objects[i].length - 1
              ),
              newValue
            );

            objects[index] = newStringValue;
          }
          // If it isnt the first item.
          // We just replace the current value for the the new one.
          else {
            const currentValue = objects[i].substring(
              objects[i].lastIndexOf(":") + 2,
              objects[i].length - 1
            );
            // Isolating the label from the value.
            const splitObjectString = objects[i].split("{");

            // Replacing value of the value object
            if (id === "uwi") {
              splitObjectString[2] = splitObjectString[2].replace(
                currentValue,
                newValue
              );
            } else {
              splitObjectString[1] = splitObjectString[1].replace(
                currentValue,
                newValue
              );
            }

            const rejoinedObjectString = splitObjectString.join("{");
            objects[index] = rejoinedObjectString;
          }
        }
      }
    }

    // Rejoin the arraay into a single string
    const reJoinedObject = objects.join(",");

    // Parse back into object set the state of the hooks.
    const finalObject = JSON.parse(reJoinedObject);

    valueChange(finalObject);
  };
  /**
   *
   * @param {String} id -id of the input to locate which value to grab in the stringified json object
   * @param {Object} inputs The object of which we will be locating the id in.
   * @returns the value of the specific input of the object
   */
  const getValue = (id, inputs) => {
    const objects = JSON.stringify(inputs).split(",");
    let objectValue;
    objects.map(function (obj, index) {
      let objectStringName = "";

      // String name of object will differ depending if it is the first item of the array or not
      if (index === 0) {
        objectStringName = obj.substring(
          obj.indexOf(":") + 3,
          obj.lastIndexOf("{") - 2
        );
      } else {
        objectStringName = obj.substring(1, obj.indexOf(":") - 1);
      }

      if (id.length === 1) {
        objectStringName = obj.substring(1, 2);
      }

      if (objectStringName === id) {
        objectValue = obj.substring(
          obj.lastIndexOf(":") + 2,
          obj.lastIndexOf('"')
        );
        if (id === "numberOfTanks") {
          console.log(objectValue);
        }
      }
    });
    return objectValue;
  };

  const handleSelectChange = (id, whichValue, section, event) => {
    const newValue = event.target.value;
    const classIndex = section - 1;

    // Split object into an array of strings
    const objects = JSON.stringify(whichValue).split(",");
    console.log(objects);

    for (let i = classIndex * 14; i < (classIndex + 1) * 14; i++) {
      let obj = objects[i];
      // Name of string to compare to id.
      const objectStringName = obj.substring(1, obj.indexOf(":") - 1);

      // Check if object contains the id of the input that needs to be changed
      if (objectStringName === id) {
        obj = objects[i + 1];
        const unitSection = obj.substring(
          obj.lastIndexOf(":") + 2,
          obj.length - 2
        );

        const newStringValue = obj.replace(unitSection, newValue);
        objects[i + 1] = newStringValue;

        break;
      }
    }
    const reJoinedObject = objects.join(",");
    const finalObject = JSON.parse(reJoinedObject);
    valueChange(finalObject);
  };

  return (
    <Grid
      style={{
        marginBottom:
          index === inputs.wellEmissionSources.length - 1 ? "20px" : "50px",
      }}
      container
      spacing={2}
    >
      <Grid style={{ textAlign: "left" }} item md={12} sm={12} xs={12}>
        <Typography variant={"h6"} color={"GrayText"}>
          {header}
        </Typography>
      </Grid>

      {labels.map(function (label, index) {
        const inputId = label[0];
        const inputLabel = label[1];
        let selectItems;
        let selectDefaultValue;
        let infoText;

        // Check if there is an array for the select box.
        if (label[2]) {
          selectItems = label[2].units;
          selectDefaultValue = label[2].default;
        }

        if (label[3]) {
          infoText = label[3];
        }

        return (
          <NumberInput
            key={inputId}
            index={index}
            //Text Inputs
            inputId={inputId + "Id"}
            className={inputId + "Group"}
            label={inputLabel}
            inputType="number"
            textValue={getValue(inputId, inputs)}
            //Select Input
            selectDefaultValue={selectDefaultValue}
            selectItems={selectItems}
            onSelectChange={(e) =>
              handleSelectChange(inputId, inputs, section, e)
            }
            selectLabel={selectLabel ? selectLabel : null}
            // Info Icon
            infoIconText={infoText}
            // Etc
            size="small"
            onTextChange={(e) => handleTextChange(inputId, inputs, section, e)}
          />
        );
      })}
      {/* <Button onClick={() => { console.log(inputs) }}>TESTING</Button> */}
    </Grid>
  );
}
