import React, { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';

import "./styles/InputStyles.css"
export default function ActionAlerts({ open, handleClose, requirements }) {


    return (
        <div className='requirementAlertWrapper'>
            <Alert className='requiremetAlertComponent' open={open} severity="error" onClose={handleClose}>
                <Typography variant='h7'>Missing required information in the {requirements ? <span style={{ color: "red" }}>{requirements[0]}</span> : null} section:</Typography>
                {requirements ? requirements.map(function (requirement, index) {
                    if (index !== 0) {
                        return <Typography key={requirement + index} variant='body1'> - {requirement}</Typography>
                    }
                }) : null}
            </Alert>

        </div>
    );
}