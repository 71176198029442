import { Button, Grid } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import MainAccordian from "./CalculatorAccordian/MainAccordian";
import Info from "./Title&Summary/Info";
import TopNav from "./TopNav/TopNav";
import Footer from "./Footer/Footer";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import "./CalculatorAccordian/AccordianStyles.css";

// Components
import ReportDialog from "./CalculatorAccordian/InputComponents.js/ReportDialog";
import ReportSendAlert from "./CalculatorAccordian/InputComponents.js/ReportSendAlert";

export default function Index() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);

  // Redirect to login page if not logged in
  useEffect(() => {
    if (!loggedIn) {
      console.log(loggedIn);
      navigate("/");
    }
  });
  // Accordian
  const displayAccordian = () => {
    return renderMainAccordian;
  };

  // Leave Website Dialog
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event (to display the prompt)
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // ESG Report Dialog
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const { information, renderMainAccordian, pdfEmail, checkRequired } =
    MainAccordian();

  const [email, setEmail] = useState();

  useEffect(() => {
    setEmail(information.information.email.value);
  }, [information]);

  // Only open Report Dialog if all required fields are filled.
  const handleReportDialogClickOpen = async () => {
    const passRequirements = await checkRequired();
    if (passRequirements) {
      setReportDialogOpen(true);
    }
  };

  const handleReportDialogClose = (resetRecaptcha) => {
    setReportDialogOpen(false);
    resetRecaptcha(false);
  };

  const displayDialog = useCallback(() => {
    return (
      <ReportDialog
        open={reportDialogOpen}
        handleClose={handleReportDialogClose}
        email={email}
        pdfFunction={pdfEmail}
        handleIsReportSent={setIsReportSent}
      />
    );
  }, [email, reportDialogOpen, pdfEmail]);

  // Report sent alert
  const [isReportSent, setIsReportSent] = useState(false);
  const handleReportSentAlertClose = () => {
    setIsReportSent(false);
  };

  return (
    <div className="container">
      <div className="content">
        <TopNav />
        <Info />
        <div>{displayAccordian()}</div>
        <div>{displayDialog()}</div>
        <div>
          {isReportSent ? (
            <ReportSendAlert
              open={isReportSent}
              handleClose={handleReportSentAlertClose}
            />
          ) : null}
        </div>
        <div>
          <Button
            onClick={handleReportDialogClickOpen}
            size="medium"
            className="reportButton"
            variant="contained"
          >
            Get full Emissions Report
          </Button>
        </div>
      </div>

      <Footer className="footerWrapper" />
    </div>
  );
}
