import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext, { AuthContextProvider } from "./context/AuthContext";
import axios from "axios";
// Components
import MainPage from "./Components/index";
import TestPage from "./testPage";
import LoginPage from "./Login/LoginPage";
import LandingPage from "./LandingPage/LandingPage";

axios.defaults.withCredentials = true;

function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/home" element={<MainPage />} />
          </Routes>
        </Router>
      </div>
    </AuthContextProvider>
  );
}

export default App;
