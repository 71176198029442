import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../AccordianStyles.css";
import HeaderandInputs from "../InputComponents.js/HeaderandInputs";
import PopoverComponent from "../InputComponents.js/PopoverComponent";
import { calculatedGor, calculatedReserves } from "../../../APIs/databaseAPI";

// Units
import {
  OilVolumeUnits,
  OilDailyVolumeUnits,
  GasVolumeUnits,
  GasDailyVolumeUnits,
} from "../../Utilities/units";
export default function ReservoirTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL) (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)

  const oilWellLabels = [
    [
      "oilRate",
      "Daily Oil Rate",
      {
        default: OilDailyVolumeUnits.units[2],
        units: OilDailyVolumeUnits.units,
      },
      "Enter the amount of oil produced in a day. You may select metres cubed (m3), gallons, barrels or litres per day.",
    ],
    [
      "oilWellGasRate",
      "Daily Gas Rate",
      {
        default: GasDailyVolumeUnits.units[1],
        units: GasDailyVolumeUnits.units,
      },
      "Enter the amount of natural gas produce in a day. You may select units in BOE (Barrels of Oil Equivalent), MCF (Thousands of Cubic Feet) or MMCF (Millions of Cubic Feet).",
    ],
    [
      "gor",
      "Gas Oil Ratio",
      [],
      "The gas/oil ratio (GOR) is the ratio of the volume of gas that comes out of solution to the volume of oil at standard conditions.",
    ],
  ];

  const gasWellLabels = [
    [
      "gasRate",
      "Daily Gas Rate",
      GasDailyVolumeUnits,
      "Enter the amount of natural gas produce in a day. You may select units in BOE (Barrels of Oil Equivalent), MCF (Thousands of Cubic Feet) or MMCF (Millions of Cubic Feet).",
    ],
  ];

  const compositionLabels = [
    [
      "methaneMol",
      "Methane (CH4) Mol %",
      [],
      "Natural Gas is 94.7% methane at standard conditions.",
    ],
    [
      "h2sMol",
      "H2S Mol%",
      [],
      "H2S is Hydrogen Suphide which can be 28% of natural gas at standard conditions.",
    ],
    [
      "n2Mol",
      "N2 Mol %",
      [],
      "N2 is nitrogen as a percentage of natural gas at standard conditions.",
    ],
    [
      "co2Mol",
      "CO2 Mol %",
      [],
      "CO2 is carbon dioxide as a percentage of natural gas at standard conditions.",
    ],
    [
      "c2Mol",
      "C2 Mol %",
      [],
      "C2 is ethane percentage of natural gas at standard conditions.",
    ],
    [
      "c3Mol",
      "C3 Mol %",
      [],
      "C3 is propane percentage of natural gas at standard conditions.",
    ],
    [
      "iC4Mol",
      "iC4 Mol %",
      [],
      "iC4 is isobutane percentage of natural gas at standard conditions.",
    ],
    [
      "nC4Mol",
      "nC4 Mol %",
      [],
      "nC4 is normal butane percentage of natural gas at standard conditions.",
    ],
    [
      "iC5Mol",
      "iC5 Mol %",
      [],
      "iC5 is isopentane percentage of natural gas at standard conditions.",
    ],
    [
      "nC5Mol",
      "nC5 Mol %",
      [],
      " nC5 is normal pentane percentage of natural gas at standard conditions.",
    ],
    [
      "c6Mol",
      "C6 Mol %",
      [],
      "C6 is Benzene percentage of natural gas at standard conditions.",
    ],
    [
      "c7Mol",
      "C7+ Mol %",
      [],
      " C7+ is toluene, ethylbenzene and xylenes percentage  of natural gas at standard conditions.",
    ],
  ];

  const yearsLabels = [
    [
      "years",
      "Remaining Producible Years",
      [],
      "Enter the expected number of years this well will continue to produce. For a marginal well near the end of its useful life this may be between 1-4 years.",
    ],
  ];

  // Hooks and States

  const [years, setYears] = useState({
    years: {
      years: { value: "", unit: "" },
    },
  });

  const [oilWell, setOilWell] = useState({
    oilWell: {
      oilRate: { value: "", unit: OilDailyVolumeUnits.units[2] },
      oilWellGasRate: { value: "", unit: GasDailyVolumeUnits.units[1] },
      gor: { value: "", unit: "" },
    },
  });

  useEffect(() => {
    const gorInput = document.getElementById("gorId");
    gorInput.setAttribute("readonly", true);
    const thisOilRateValue = oilWell.oilWell.oilRate.value;
    const thisOilRateUnit = oilWell.oilWell.oilRate.unit;
    const thisOilWellGasRateValue = oilWell.oilWell.oilWellGasRate.value;
    const thisOilWellGasRateUnit = oilWell.oilWell.oilWellGasRate.unit;
    calculatedGor(
      thisOilRateValue,
      thisOilRateUnit,
      thisOilWellGasRateValue,
      thisOilWellGasRateUnit,
      function (response) {
        console.log("GOR RESPONSE :" + response);
        setOilWell((prevState) => ({
          ...prevState,
          oilWell: {
            ...prevState.oilWell,
            gor: { value: response, unit: prevState.oilWell.gor.unit },
          },
        }));
        gorInput.value = response;
      }
    );
  }, [
    oilWell.oilWell.oilRate.unit,
    oilWell.oilWell.oilRate.value,
    oilWell.oilWell.oilWellGasRate.unit,
    oilWell.oilWell.oilWellGasRate.value,
  ]);

  const [gasWell, setGasWell] = useState({
    gasWell: {
      gasRate: { value: "", unit: GasDailyVolumeUnits.units[1] },
    },
  });

  const remainingReservesLabels = [
    ["calculatedReserve", "Calculated", [], "Calculated info text"],
    [
      "knownReserve",
      "Known (Optional)",
      { default: OilVolumeUnits.units[2], units: OilVolumeUnits.units },
      "Known remaining oil reserves of the reservoir.",
    ],
  ];

  const [remainingReserves, setRemainingReserves] = useState({
    remainingReserves: {
      calculatedReserve: { value: "", unit: "" },
      knownReserve: { value: "", unit: OilVolumeUnits.units[2] },
    },
  });

  // Dynamically renders the Calculated Reserves
  useEffect(() => {
    const calculatedInput = document.getElementById("calculatedReserveId");
    calculatedInput.setAttribute("readonly", true);
    const producibleYears = years.years.years.value;
    const thisOilRateValue = oilWell.oilWell.oilRate.value;
    const thisOilRateUnit = oilWell.oilWell.oilRate.unit;
    const thisOilWellGasRateValue = oilWell.oilWell.oilWellGasRate.value;
    const thisOilWellGasRateUnit = oilWell.oilWell.oilWellGasRate.unit;
    const thisGasRateValue = gasWell.gasWell.gasRate.value;
    const thisGasRateUnit = gasWell.gasWell.gasRate.unit;

    calculatedReserves(
      producibleYears,
      thisOilRateValue,
      thisOilRateUnit,
      thisOilWellGasRateValue,
      thisOilWellGasRateUnit,
      thisGasRateValue,
      thisGasRateUnit,
      function (response) {
        calculatedInput.value = response.toFixed(2);
      }
    );
  }, [
    gasWell.gasWell.gasRate.unit,
    gasWell.gasWell.gasRate.value,
    oilWell.oilWell.oilRate.unit,
    oilWell.oilWell.oilRate.value,
    oilWell.oilWell.oilWellGasRate.unit,
    oilWell.oilWell.oilWellGasRate.value,
    years.years.years.value,
  ]);

  const [composition, setComposition] = useState({
    composition: {
      methaneMol: { value: "94.7", unit: "" },
      h2sMol: { value: "", unit: "" },
      n2Mol: { value: "", unit: "" },
      co2Mol: { value: "", unit: "" },
      c2Mol: { value: "", unit: "" },
      c3Mol: { value: "", unit: "" },
      iC4Mol: { value: "", unit: "" },
      nC4Mol: { value: "", unit: "" },
      iC5Mol: { value: "", unit: "" },
      nC5Mol: { value: "", unit: "" },
      c6Mol: { value: "", unit: "" },
      c7Mol: { value: "", unit: "" },
    },
  });

  // Toggle Options
  const wellOptions = ["Oil", "Gas"];
  const oilDisabledOptions = ["oilRate", "oilWellGasRate", "gor"];
  const gasDisabledOptions = ["gasRate"];
  const [selectedWellOption, setSelectedWellOption] = useState(wellOptions[0]);

  const handleToggle = useCallback(
    (option) => {
      console.log(selectedWellOption);

      console.log(selectedWellOption);

      setSelectedWellOption(option);
      if (selectedWellOption === "Oil") {
        setOilWell({
          oilWell: {
            oilRate: { value: "", unit: OilDailyVolumeUnits.units[2] },
            oilWellGasRate: { value: "", unit: GasDailyVolumeUnits.units[1] },
            oilWellReserves: { value: "", unit: OilVolumeUnits.units[2] },
            gor: { value: "", unit: "" },
          },
        });
      } else if (selectedWellOption === "Gas") {
        setGasWell({
          gasWell: {
            gasRate: { value: "", unit: GasDailyVolumeUnits.units[1] },
            gasWellReserves: { value: "", unit: GasVolumeUnits.units[2] },
          },
        });
      }
    },
    [selectedWellOption]
  );

  useEffect(() => {
    if (selectedWellOption === "Oil") {
      gasDisabledOptions.map(function (option, index) {
        document
          .getElementById(option + "Id")
          .setAttribute("disabled", "disabled");
        document.getElementById(option + "Id").style.backgroundColor =
          "rgba(60, 60, 60, 0.4)";
        document.getElementById(option + "Id").value = "";
        document
          .getElementById(option + "LabelId")
          .setAttribute("style", "opacity: 0.5;");
      });
      oilDisabledOptions.map(function (option, index) {
        document.getElementById(option + "Id").removeAttribute("disabled");
        document.getElementById(option + "Id").style.backgroundColor = "white";
        document
          .getElementById(option + "LabelId")
          .setAttribute("style", "opacity: 1.0;");
      });
    } else if (selectedWellOption === "Gas") {
      gasDisabledOptions.map(function (option, index) {
        document.getElementById(option + "Id").removeAttribute("disabled");
        document.getElementById(option + "Id").style.backgroundColor = "white";
        document
          .getElementById(option + "LabelId")
          .setAttribute("style", "opacity: 1.0;");
      });
      oilDisabledOptions.map(function (option, index) {
        document
          .getElementById(option + "Id")
          .setAttribute("disabled", "disabled");
        document.getElementById(option + "Id").value = "";
        document.getElementById(option + "Id").style.backgroundColor =
          "rgba(60, 60, 60, 0.4)";
        document
          .getElementById(option + "LabelId")
          .setAttribute("style", "opacity: 0.5;");
      });
    }
  }, [gasDisabledOptions, oilDisabledOptions, selectedWellOption]);

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const reservoirObject = populatedUWI.reservoir;
      // Years
      setYears({
        years: {
          years: { value: reservoirObject.years.toString(), unit: "" },
        },
      });

      // Selected Well Option
      setSelectedWellOption(reservoirObject.selectedWellOption);
      console.log(reservoirObject.selectedWellOption);
      if (reservoirObject.selectedWellOption === "Oil") {
        // Oil Well
        const newOilRate = reservoirObject.oilWell.oilRate;
        const newOilRateUnit = reservoirObject.oilWell.oilRateUnit;
        const newOilWellGasRate = reservoirObject.oilWell.oilWellGasRate;
        const newOilWellGasRateUnit =
          reservoirObject.oilWell.oilWellGasRateUnit;
        const newGor = reservoirObject.oilWell.gor;

        setOilWell({
          oilWell: {
            oilRate: { value: newOilRate.toString(), unit: newOilRateUnit },
            oilWellGasRate: {
              value: newOilWellGasRate.toString(),
              unit: newOilWellGasRateUnit,
            },
            gor: { value: newGor.toString(), unit: "" },
          },
        });
      } else if (reservoirObject.selectedWellOption === "Gas") {
        const newGasRate = reservoirObject.gasWell.gasRate;
        const newGasRateUnit = reservoirObject.gasWell.gasRateUnit;
        setGasWell({
          gasWell: {
            gasRate: { value: newGasRate.toString(), unit: newGasRateUnit },
          },
        });
      }

      const newKnown = reservoirObject.knownReserves;
      const newKnownUnit = reservoirObject.knownReservesUnit;
      setRemainingReserves({
        remainingReserves: {
          calculatedReserve: { value: "", unit: "" },
          knownReserve: {
            value: newKnown ? newKnown.toString() : null,
            unit: newKnownUnit,
          },
        },
      });

      const compositionObj = reservoirObject.composition;
      setComposition({
        composition: {
          methaneMol: {
            value: compositionObj.methaneMol
              ? compositionObj.methaneMol.toString()
              : null,
            unit: "",
          },
          h2sMol: {
            value: compositionObj.h2sMol
              ? compositionObj.h2sMol.toString()
              : null,
            unit: "",
          },
          n2Mol: {
            value: compositionObj.n2Mol
              ? compositionObj.n2Mol.toString()
              : null,
            unit: "",
          },
          co2Mol: {
            value: compositionObj.co2Mol
              ? compositionObj.co2Mol.toString()
              : null,
            unit: "",
          },
          c2Mol: {
            value: compositionObj.c2Mol
              ? compositionObj.c2Mol.toString()
              : null,
            unit: "",
          },
          c3Mol: {
            value: compositionObj.c3Mol
              ? compositionObj.c3Mol.toString()
              : null,
            unit: "",
          },
          iC4Mol: {
            value: compositionObj.iC4Mol
              ? compositionObj.iC4Mol.toString()
              : null,
            unit: "",
          },
          nC4Mol: {
            value: compositionObj.nC4Mol
              ? compositionObj.nC4Mol.toString()
              : null,
            unit: "",
          },
          iC5Mol: {
            value: compositionObj.iC5Mol
              ? compositionObj.iC5Mol.toString()
              : null,
            unit: "",
          },
          nC5Mol: {
            value: compositionObj.nC5Mol
              ? compositionObj.nC5Mol.toString()
              : null,
            unit: "",
          },
          c6Mol: {
            value: compositionObj.c6Mol
              ? compositionObj.c6Mol.toString()
              : null,
            unit: "",
          },
          c7Mol: {
            value: compositionObj.c7Mol
              ? compositionObj.c7Mol.toString()
              : null,
            unit: "",
          },
        },
      });
    }
  }, [populatedUWI]);

  return {
    years,
    oilWell,
    gasWell,
    composition,
    remainingReserves,
    selectedWellOption,
    renderReservoir: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            tabIndex={1}
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">
                Pool/Reservoir{" "}
                <span style={{ color: "red", fontSize: "0.7em" }}>
                  *Required
                </span>
              </Typography>
              <div>
                <PopoverComponent
                  infoText={
                    "This section focuses on source and type of production coming from the well."
                  }
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Remaining Asset Life"
                    labels={yearsLabels}
                    inputs={years}
                    valueChange={setYears}
                    requiredList={[true]}
                    gridSize={9}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Oil Well"
                    labels={oilWellLabels}
                    inputs={oilWell}
                    valueChange={setOilWell}
                    toggleOptions={wellOptions}
                    toggleInput={selectedWellOption}
                    toggleChange={handleToggle}
                    requiredList={[selectedWellOption === "Oil" ? true : false]}
                    gridSize={4}
                    populatedUWI={populatedUWI}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Gas Well"
                    labels={gasWellLabels}
                    inputs={gasWell}
                    valueChange={setGasWell}
                    requiredList={[selectedWellOption === "Gas" ? true : false]}
                    gridSize={4}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Remaining Reserves"
                    labels={remainingReservesLabels}
                    inputs={remainingReserves}
                    valueChange={setRemainingReserves}
                    gridSize={6}
                    calculatedLabel={"Calculated"}
                  />
                </Grid>
                <Grid item md={12}>
                  <HeaderandInputs
                    header="Composition"
                    labels={compositionLabels}
                    inputs={composition}
                    valueChange={setComposition}
                    requiredList={[true]}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Button onClick={() => console.log(selectedOption)}>CHECK SELECT</Button> */}
      </div>
    ),
  };
}
