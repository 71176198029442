import React, { useRef, useEffect } from 'react'
import { FormHelperText, TextField, InputLabel, Select, MenuItem, Grid, Typography } from '@mui/material'

import PopoverComponent from './PopoverComponent'

// Styles
import "./styles/InputStyles.css"


/**
 * @param {Event Handler} onChange An onChange event that handles changing of text.
 * @param {String} inputId The name of the ID for the Textfield and htmlFor.
 * @param {String} label The name of the label for the input.
 * @param {String} inputType The value for the 'type' property of a TextField. 
 * @return Rendered label and input for an open text field. 
 */
export default function NumberInput({ onTextChange, inputId, label, inputType, size, textValue, onSelectChange, selectItems, selectDefaultValue, index, infoIconText, selectLabel, gridSize, disabled, requiredList }) {


    /**
     * 
     * @param {Number} gridSize A number that determinesthe grid size for the inputs.
     * Default is 3 if there is not grid size.
     * @returns An integer representing how many columns should be used for the inputs. (Ex. column of 3)
     */
    const setGridSize = (gridSize) => {
        if (gridSize) {
            return gridSize
        } else {
            return 3
        }
    }

    /**
     * 
     * @param {Array} requiredList An array of boolean values that determine if an input is required or not.
     * @param {Number} index Index of which boolean value in the array
     * @param {String} label A string used for the label of the input
     * @returns A string that will be used as the label for those that are required.
     */
    function getLabel(requiredList, index, label) {
        const currentRequiredInput = requiredList[index]
        if (currentRequiredInput === true) {
            return <div>
                {label + " "}
                <span style={{ color: "red" }}>
                    *Required
                </span>
            </div>;
        } else {
            return label + ":";
        }
    }
    const myTextFieldRef = useRef(null);

    const preventIncrementScroll = (event) => {
        event.preventDefault();
    }
    useEffect(() => {
        const input = myTextFieldRef.current;

        input.addEventListener('wheel', preventIncrementScroll, { passive: false });



        return () => {
            input.removeEventListener('wheel', preventIncrementScroll);

        };
    }, []);



    return (

        <Grid className="inputGridItem" item lg={setGridSize(gridSize, inputType)} md={gridSize ? setGridSize(gridSize, inputType) : 6} sm={6} xs={12}>
            {/*----------- Label -------- */}
            <div className='flexWrapper'>

                <p

                    htmlFor={inputId}
                    className='numberFormLabels'

                    id={inputId + "LabelId"}
                >
                    {/*If there isnt a required list. Just create a regular label. If there is, use getLabel() */}
                    {requiredList ? getLabel(requiredList, index, label) : label + ":"}

                </p>


                {infoIconText ? <PopoverComponent infoText={infoIconText} /> : ""}
            </div>
            {/*----------- Input -------- */}
            <div className='flexWrapper'>
                <TextField
                    id={inputId + "Id"}
                    className='numberFormInputs'
                    value={textValue}
                    // Default type is number if not specified.
                    type={inputType ? inputType : 'number'}
                    onChange={onTextChange}
                    size={size}
                    //  Removes the "Please enter valid number between x and y" browser dialog.
                    inputProps={{
                        step: "any",


                    }}
                    inputRef={myTextFieldRef}



                />
                {/*----------- Select -------- */}
                {selectItems ?
                    <div>
                        <Select
                            size='small'
                            className='selectFormInputs'
                            defaultValue={selectDefaultValue}
                            onChange={onSelectChange}
                            id={inputId + "SelectId"}

                        >
                            {selectItems.map(function (item, index) {
                                return (
                                    <MenuItem
                                        key={item + index}
                                        value={selectItems[index]}
                                    >
                                        {item}
                                    </MenuItem>)
                            })}
                        </Select>
                        <FormHelperText style={{ textAlign: "center" }}>{selectLabel}</FormHelperText>
                    </div>
                    : ""}
            </div>



        </Grid>
    )
}
