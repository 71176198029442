import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReCAPTCHA from "react-google-recaptcha";

//APIs
import { captchaCheck } from "../../../APIs/databaseAPI";

import MainAccordian from "../MainAccordian";
import { Typography } from "@mui/material";

export default function ReportDialog({
  open,
  handleClose,
  email,
  pdfFunction,
  handleIsReportSent,
}) {
  const [currentEmail, setCurrentEmail] = useState(email);
  const [emailLabel, setEmailLabel] = useState(email);
  const [captchValue, setCaptchaValue] = useState();
  const [captchaMessage, setCaptchaMessage] = useState();

  useEffect(() => {
    setCurrentEmail(email);
    setEmailLabel(email);
  }, [email]);

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    setCurrentEmail(newValue);
  };

  const handleCaptchaOnChange = (value) => {
    setCaptchaValue(value);
  };

  //Save to DB and send PDF report email
  const onSubmit = async () => {
    if (captchValue) {
      await captchaCheck(captchValue, function (response) {
        const captchaSuccess = response;
        if (captchaSuccess) {
          try {
            pdfFunction(currentEmail);
            setCaptchaMessage("");
            handleIsReportSent(true);
            handleClose(setCaptchaValue);
          } catch (error) {}
        } else {
          setCaptchaMessage("You did not pass the reCaptcha.");
        }
      });
    } else {
      setCaptchaMessage("Please check the reCAPTCHA to proceed.");
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(setCaptchaValue)}>
        <DialogTitle style={{ fontWeight: "bolder" }}>
          We have your results!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Can we use{" "}
            <span style={{ fontWeight: "700", fontStyle: "italic" }}>
              {emailLabel}?
            </span>{" "}
            If not, enter your email below to get a{" "}
            <span style={{ fontWeight: "600" }}>personalized report</span> send
            to your inbox!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={currentEmail}
            onChange={(e) => handleTextChange(e)}
          />
        </DialogContent>

        <DialogContent>
          {ReCAPTCHA ? (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaOnChange}
              // ref={(ref) => {
              //     // Store a reference to the ReCaptcha component
              //     if (ref) {
              //         ref.reset(); // Reset the ReCaptcha component when it is mounted
              //     }
              // }}
            />
          ) : (
            <span>Captcha loading..</span>
          )}
          <Typography style={{ color: "red" }} variant="caption">
            {captchaMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={onSubmit}>
            Send my report
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
