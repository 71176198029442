import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../AccordianStyles.css";

// Components
import HeaderandInputs from "../InputComponents.js/HeaderandInputsForArray";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import {
  LengthUnits,
  VolumePerHourUnits,
  PressureUnits,
} from "../../Utilities/units";

export default function WellEmissionSourceTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)
  const wellEmissionSourcesLabels = [
    ["uwi", "UWI"],
    [
      "casingDiameter",
      "Casing Diameter",
      { default: LengthUnits.units[2], units: LengthUnits.units },
    ],
    ["wellDepth", "Well Depth", LengthUnits],
    ["gaugePressure", "Gauge Pressure", PressureUnits],
    ["hoursOpen", "Hours Open"],
    ["productionRate", "Production Rate", VolumePerHourUnits],
    ["eventsPerMonth", "Events per Month"],
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wellEmissionSourceObject = {
    uwi: { value: "", unit: "" },
    casingDiameter: { value: "22", unit: LengthUnits.units[2] },
    wellDepth: { value: "2000", unit: LengthUnits.default },
    gaugePressure: { value: "1200", unit: PressureUnits.default },
    hoursOpen: { value: "0.3", unit: "" },
    productionRate: { value: "10", unit: VolumePerHourUnits.default },
    eventsPerMonth: { value: "", unit: "" },
  };

  const [wellEmissionSources, setWellEmissionSources] = useState({
    wellEmissionSources: [wellEmissionSourceObject],
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const wellObjFirstItem =
        populatedUWI.wellEmissionSourcesPerMonth.wells[0];

      setWellEmissionSources({
        wellEmissionSources: [
          {
            uwi: { value: wellObjFirstItem.uwi.value, unit: "" },
            casingDiameter: {
              value: wellObjFirstItem.casingDiameter.value,
              unit: wellObjFirstItem.casingDiameter.unit,
            },
            wellDepth: {
              value: wellObjFirstItem.wellDepth.value,
              unit: wellObjFirstItem.wellDepth.unit,
            },
            gaugePressure: {
              value: wellObjFirstItem.gaugePressure.value,
              unit: wellObjFirstItem.gaugePressure.unit,
            },
            hoursOpen: { value: wellObjFirstItem.hoursOpen.value, unit: "" },
            productionRate: {
              value: wellObjFirstItem.productionRate.value,
              unit: wellObjFirstItem.productionRate.unit,
            },
            eventsPerMonth: {
              value: wellObjFirstItem.eventsPerMonth.value,
              unit: "",
            },
          },
        ],
      });
    }
  }, [populatedUWI]);

  // For syncing up the re-rendering of this tab
  const [render, rerender] = useState(false);

  // For adding another source to well emissions
  const addOne = useCallback(() => {
    const temp = wellEmissionSources;
    temp.wellEmissionSources.push(wellEmissionSourceObject);
    setWellEmissionSources(temp);
    rerender(!render);
  }, [render, wellEmissionSourceObject, wellEmissionSources]);

  const increment = (index) => {
    return index + 1;
  };

  return {
    wellEmissionSources,
    render,
    renderWellEmissions: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Well Emission Sources</Typography>

              <PopoverComponent
                infoText={
                  "Enter any other known sources of emissions at the well site."
                }
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              {wellEmissionSources.wellEmissionSources.map((source, index) => (
                <Grid key={index} item md={12}>
                  <HeaderandInputs
                    header={"Source " + increment(index)}
                    labels={wellEmissionSourcesLabels}
                    inputs={wellEmissionSources}
                    valueChange={setWellEmissionSources}
                    section={increment(index)}
                    index={index}
                  />
                  {index ===
                  wellEmissionSources.wellEmissionSources.length - 1 ? (
                    <Button
                      style={{ float: "left" }}
                      variant="contained"
                      onClick={addOne}
                    >
                      + source
                    </Button>
                  ) : null}
                </Grid>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>

        {/* <Button onClick={() => console.log(wellEmissionSources.wellEmissionSources)}>WELL TEST</Button> */}
      </div>
    ),
  };
}
