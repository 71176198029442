import React, { useState, useCallback, useEffect } from "react";
import NumberInput from "./NumberInput";
import {
  Grid,
  Typography,
  Button,
  ButtonGroup,
  InputLabel,
} from "@mui/material";

import "./styles/InputStyles.css";
import PopoverComponent from "./PopoverComponent";

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, ...items) {
  this.splice(index, 0, ...items);
};

/**
 *
 * @param {   } param0
 * @returns
 */
export default function HeaderandInputs({
  header,
  headerInfoIconText,
  labels,
  inputs,
  valueChange,
  selectLabel,
  gridSize,
  inputTypes,
  toggleOptions,
  toggleInput,
  toggleChange,
  requiredList,
}) {
  const [sectionInputs, setSectionInputs] = useState(inputs);

  useEffect(() => {
    setSectionInputs(inputs);
  }, [inputs]);
  /**
   * @param {String} id The id of the input that is changing.
   * @param {Object} whichValue The object that needs to be stringified and changed.
   * @param {Event} event The event object that holds the new value.
   */
  const handleTextChange = useCallback(
    (id, whichValue, event) => {
      const newValue = event.target.value;
      // Break JSON object into array of strings.
      const objects = JSON.stringify(whichValue).split(",");

      // eslint-disable-next-line array-callback-return
      objects.map(function (obj, index) {
        let objectStringName = "";

        // String name of object will differ depending if it is the first item of the array or not
        if (index === 0) {
          objectStringName = obj.substring(
            obj.indexOf(":") + 3,
            obj.lastIndexOf("{") - 2
          );
        } else {
          objectStringName = obj.substring(1, obj.indexOf(":") - 1);
        }

        if (id.length === 1) {
          objectStringName = obj.substring(1, 2);
        }

        // Check if array item string is equal to the ID of text to change.
        if (objectStringName === id) {
          // If this is this is the first time changing the value.
          if (obj.charAt(obj.lastIndexOf(":") + 2) === '"') {
            const newStringValue = obj.replace(
              'value":""',
              'value":"' + newValue + '"'
            );

            objects[index] = newStringValue;
          }
          // Else If there is already a value in the input field
          else {
            // If the input is equal to the first item of the array.
            // We will take out surrounding object name.
            if (index === 0) {
              // Dealing with substring and extra characters
              let currentValueCutOff = 0;
              let currentValueAddOn = '"}';

              if (!obj.endsWith("}")) {
                currentValueCutOff = 1;
                currentValueAddOn = "";
              }
              // Isolating the value section of the string.
              const firstPartofString = obj.substring(0, obj.indexOf(":") + 2);
              // Take out surrounding object name EX. tankVentingEmissions.
              obj = obj.replace(obj.substring(0, obj.indexOf(":") + 2), "");
              objects[0] = obj;

              // Storing the current value
              const currentValue = obj.substring(
                obj.lastIndexOf(":") + 2,
                obj.length - currentValueCutOff
              );

              // Replacing the current value with the newValue
              const splitObjectString = obj.split(":");

              splitObjectString[2] = splitObjectString[2].replace(
                currentValue,
                newValue + currentValueAddOn
              );

              const rejoinedObjectString = splitObjectString.join(":");
              // Re-joining the surrounding object wwith the inner object.
              const completeFirstItem =
                firstPartofString + rejoinedObjectString;
              objects[index] = completeFirstItem;
            }
            // If it isnt the first item of the array.
            // We just replace the current value for the the new one.
            else {
              const currentValue = obj.substring(
                obj.lastIndexOf(":") + 2,
                obj.length - 1
              );
              // Isolating the label from the value.
              const splitObjectString = obj.split(":");

              // Replacing value of the value object
              splitObjectString[2] = splitObjectString[2].replace(
                currentValue,
                newValue
              );

              const rejoinedObjectString = splitObjectString.join(":");
              objects[index] = rejoinedObjectString;
            }
          }
        }
      });
      // Rejoin the arraay into a single string
      const reJoinedObject = objects.join(",");

      // Parse back into object set the state of the hooks.
      const finalObject = JSON.parse(reJoinedObject);
      setSectionInputs(finalObject);
      valueChange(finalObject);
    },
    [sectionInputs, valueChange]
  );

  /**
   * @param {String} id The id of the input that is changing.
   * @param {Object} whichValue The object that needs to be stringified and changed.
   * @param {Event} event The event object that holds the new value.
   */
  const handleSelectChange = (id, whichValue, event) => {
    const newValue = event.target.value;
    // Split object into an array of strings
    const objects = JSON.stringify(whichValue).split("}");
    // eslint-disable-next-line array-callback-return
    objects.map(function (obj, index) {
      let objectStringName = "";

      // String name of object will differ depending if it is the first item of the array or not
      if (index === 0) {
        objectStringName = obj.substring(
          obj.indexOf(":") + 3,
          obj.lastIndexOf("{") - 2
        );
      } else {
        objectStringName = obj.substring(2, obj.indexOf(":") - 1);
      }

      // Check if object contains the id of the input that needs to be changed
      if (objectStringName === id) {
        // This logic is for when populating inputs based on UWI but this child component doesnt keep up with the value changes.
        const currentInputValue = document.getElementById(
          objectStringName + "Id"
        ).value;
        const valueSections = obj.split("{");
        // last section is the value and unit part of the string.
        const lastSection = valueSections[valueSections.length - 1];

        const valueQuoteSection = lastSection.substring(
          lastSection.indexOf('value":') + 2,
          lastSection.lastIndexOf(":") - 7
        );
        // Replace the "" for value with the current value.
        const newLastSection = lastSection.replace(
          valueQuoteSection,
          'lue":"' + currentInputValue + '"'
        );
        // Replace last section of array with our maniuplated version
        valueSections[valueSections.length - 1] = newLastSection;
        //Rejoin and replace the obj entirely.
        const newValueObject = valueSections.join("{");
        obj = newValueObject;

        const unitSection = obj.substring(obj.lastIndexOf(":") + 1, obj.length);

        const newStringValue = obj.replace(unitSection, '"' + newValue + '"');
        objects[index] = newStringValue;
      }
    });
    const reJoinedObject = objects.join("}");
    const finalObject = JSON.parse(reJoinedObject);
    setSectionInputs(finalObject);
    valueChange(finalObject);
  };

  /**
   *
   * @param {Array} labels An array of arrays for labeling purposes
   * @param {Number} index Determines the index of which label to use.
   * @returns The select default value which will be the 3 item of the inner array.
   */
  const getSelectDefaultValue = (labels, index) => {
    const thisLabel = labels[index];
    return thisLabel[2].default;
  };

  /**
   *
   * @param {Array} labels An array of arrays for labeling purposes
   * @param {Number} index Determines the index of which label to use.
   * @returns An array that holds all the unit options for the drop-down.
   */
  const getSelectItems = (labels, index) => {
    const thisLabel = labels[index];
    return thisLabel[2].units;
  };

  /**
   * @description Handles the toggle of color change when clicked.
   * @param {String} toggleInput The current option selected for the well type.
   * @param {String} option The option for the well type.
   * @returns The color style for either well types.
   */
  function getToggleInputStyle(toggleInput, option) {
    return {
      color: toggleInput === option ? "white" : "#ff9fa7",
      backgroundColor: toggleInput === option ? "#ff9fa7" : "white",
      width: "80px",
    };
  }

  /**
   *
   * @param {String} id -id of the input to locate which value to grab in the stringified json object
   * @param {Object} inputs The object of which we will be locating the id in.
   * @returns the value of the specific input of the object
   */
  const getValue = (id, inputs) => {
    const objects = JSON.stringify(inputs).split(",");
    let objectValue;
    objects.map(function (obj, index) {
      let objectStringName = "";

      // String name of object will differ depending if it is the first item of the array or not
      if (index === 0) {
        objectStringName = obj.substring(
          obj.indexOf(":") + 3,
          obj.lastIndexOf("{") - 2
        );
      } else {
        objectStringName = obj.substring(1, obj.indexOf(":") - 1);
      }

      if (id.length === 1) {
        objectStringName = obj.substring(1, 2);
      }

      if (objectStringName === id) {
        objectValue = obj.substring(
          obj.lastIndexOf(":") + 2,
          obj.lastIndexOf('"')
        );
      }
    });
    return objectValue;
  };

  return (
    <Grid container spacing={2}>
      {toggleOptions ? (
        <Grid style={{ textAlign: "left" }} item md={12}>
          {/* Well type button toggle section */}
          <div className="flex-center-row">
            <Typography variant={"h6"} color={"GrayText"}>
              Well Type
            </Typography>
            <PopoverComponent
              infoText={
                "Select Oil if most of the production is classified as oil. Select Gas if the majority of the production is natural gas."
              }
            />
          </div>

          <ButtonGroup
            style={{
              marginTop: "10px",
            }}
          >
            {toggleOptions.map(function (option, index) {
              return (
                <Button
                  key={option}
                  style={getToggleInputStyle(toggleInput, option)}
                  variant="text"
                  size="medium"
                  onClick={(e) => toggleChange(option)}
                >
                  {option}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      ) : null}

      {/* Header section */}
      <Grid
        style={{
          textAlign: "left",
          marginTop: header === "Multi-Well/Facility ID" ? "30px" : null,
        }}
        item
        md={12}
        sm={12}
        xs={12}
      >
        <div className="flex-center-row">
          <Typography variant={"h6"} color={"GrayText"}>
            {header}
          </Typography>
          {headerInfoIconText ? (
            <PopoverComponent infoText={headerInfoIconText} />
          ) : null}
        </div>
      </Grid>

      {labels.map(function (label, index) {
        const inputId = label[0];
        const inputLabel = label[1];
        let selectItems;
        let selectDefaultValue;
        let infoText;
        let thisValue = getValue(inputId, sectionInputs);

        if (label[3]) {
          infoText = label[3];
        }

        return (
          <NumberInput
            key={inputId}
            index={index}
            //Text Inputs
            inputId={inputId}
            label={inputLabel}
            inputType={inputTypes ? inputTypes[index] : null}
            textValue={thisValue ? thisValue : null}
            //Select Input
            selectDefaultValue={
              label[2] ? getSelectDefaultValue(labels, index) : null
            }
            selectItems={label[2] ? getSelectItems(labels, index) : null}
            onSelectChange={(e) =>
              handleSelectChange(inputId, sectionInputs, e)
            }
            selectLabel={selectLabel ? selectLabel : null}
            // Info Icon
            infoIconText={infoText}
            // Etc
            size="small"
            onTextChange={(e) => handleTextChange(inputId, sectionInputs, e)}
            gridSize={gridSize}
            requiredList={requiredList}
          />
        );
      })}
    </Grid>
  );
}
