import React, { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Styles
import "../AccordianStyles.css";

// Components
import HeaderandInputs from "../InputComponents.js/HeaderandInputs";
import HeaderandInputsForMap from "../InputComponents.js/HeaderandInputsForMap";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import { LengthUnits } from "../../Utilities/units";

export default function InformationTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)
  const informationLabels = [
    ["name", "Name"],
    ["company", "Company"],
    ["email", "Email"],
  ];

  // Input States
  const [information, setInformation] = useState({
    information: {
      name: { value: "", unit: "" },
      company: { value: "", unit: "" },
      email: { value: "", unit: "" },
    },
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const informationObj = populatedUWI;

      setInformation({
        information: {
          name: { value: informationObj.name, unit: "" },
          company: { value: informationObj.company, unit: "" },
          email: {
            value: informationObj.email ? informationObj.email : "",
            unit: "",
          },
        },
      });
    }
  }, [populatedUWI]);

  return {
    information,
    setInformation,
    renderInformation: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">
                User Info{" "}
                <span style={{ color: "red", fontSize: "0.7em" }}>
                  *Required
                </span>
              </Typography>
              <div>
                <PopoverComponent
                  infoText={"Enter your name and email address."}
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              <Grid container spacing={1}>
                <Grid item md={8}>
                  <HeaderandInputs
                    labels={informationLabels}
                    inputs={information}
                    valueChange={setInformation}
                    inputTypes={["text", "text", "text"]}
                    requiredList={[true, false, true]}
                    gridSize={12}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
