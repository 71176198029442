import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../AccordianStyles.css";

// Components
import InternalTab from "../InputComponents.js/InternalTabComponent";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

export default function FugitiveEmissionsTab(populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)

  const gasValveSweetLabels = [
    ["gasValveSweet1000ppm", "# 0 - 1000ppm"],
    ["hoursGasValveSweet1000ppm", "Annual Operating Hours"],
    ["gasValveSweetUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasValveSweetUpTo10000ppm", "Annual Operating Hours"],
    ["gasValveSweetAbove10000ppm", "# > 10000ppm"],
    ["hoursGasValveSweetAbove10000ppm", "Annual Operating Hours"],
  ];
  const gasValveSourLabels = [
    ["gasValveSour1000ppm", "# 0 - 1000ppm"],
    ["hoursGasValveSour1000ppm", "Annual Operating Hours"],
    ["gasValveSourUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasValveSourUpTo10000ppm", "Annual Operating Hours"],
    ["gasValveSourAbove10000ppm", "# > 10000ppm"],
    ["hoursGasValveSourAbove10000ppm", "Annual Operating Hours"],
  ];
  const gasValveLightLabels = [
    ["gasValveLight1000ppm", "# 0 - 1000ppm"],
    ["hoursGasValveLight1000ppm", "Annual Operating Hours"],
    ["gasValveLightUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasValveLightUpTo10000ppm", "Annual Operating Hours"],
    ["gasValveLightAbove10000ppm", "# > 10000ppm"],
    ["hoursGasValveLightAbove10000ppm", "Annual Operating Hours"],
  ];

  const oilValveGasLabels = [
    ["oilValveGas1000ppm", "# 0 - 1000ppm"],
    ["hoursOilValveGas1000ppm", "Annual Operating Hours"],
    ["oilValveGasUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursOilValveGasUpTo10000ppm", "Annual Operating Hours"],
    ["oilValveGasAbove10000ppm", "# > 10000ppm"],
    ["hoursOilValveGasAbove10000ppm", "Annual Operating Hours"],
  ];

  const oilValveLightLabels = [
    ["oilValveLight1000ppm", "# 0 - 1000ppm"],
    ["hoursOilValveLight1000ppm", "Annual Operating Hours"],
    ["oilValveLightUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursOilValveLightUpTo10000ppm", "Annual Operating Hours"],
    ["oilValveLightAbove10000ppm", "# > 10000ppm"],
    ["hoursOilValveLightAbove10000ppm", "Annual Operating Hours"],
  ];

  const gasConnectorSweetLabels = [
    ["gasConnectorSweet1000ppm", "# 0 - 1000ppm"],
    ["hoursGasConnectorSweet1000ppm", "Annual Operating Hours"],
    ["gasConnectorSweetUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasConnectorSweetUpTo10000ppm", "Annual Operating Hours"],
    ["gasConnectorSweetAbove10000ppm", "# > 10000ppm"],
    ["hoursGasConnectorSweetAbove10000ppm", "Annual Operating Hours"],
  ];
  const gasConnectorSourLabels = [
    ["gasConnectorSour1000ppm", "# 0 - 1000ppm"],
    ["hoursGasConnectorSour1000ppm", "Annual Operating Hours"],
    ["gasConnectorSourUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasConnectorSourUpTo10000ppm", "Annual Operating Hours"],
    ["gasConnectorSourAbove10000ppm", "# > 10000ppm"],
    ["hoursGasConnectorSourAbove10000ppm", "Annual Operating Hours"],
  ];
  const gasConnectorLightLabels = [
    ["gasConnectorLight1000ppm", "# 0 - 1000ppm"],
    ["hoursGasConnectorLight1000ppm", "Annual Operating Hours"],
    ["gasConnectorLightUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursGasConnectorLightUpTo10000ppm", "Annual Operating Hours"],
    ["gasConnectorLightAbove10000ppm", "# > 10000ppm"],
    ["hoursGasConnectorLightAbove10000ppm", "Annual Operating Hours"],
  ];

  const oilConnectorGasLabels = [
    ["oilConnectorGas1000ppm", "# 0 - 1000ppm"],
    ["hoursOilConnectorGas1000ppm", "Annual Operating Hours"],
    ["oilConnectorGasUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursOilConnectorGasUpTo10000ppm", "Annual Operating Hours"],
    ["oilConnectorGasAbove10000ppm", "# > 10000ppm"],
    ["hoursOilConnectorGasAbove10000ppm", "Annual Operating Hours"],
  ];

  const oilConnectorLightLabels = [
    ["oilConnectorLight1000ppm", "# 0 - 1000ppm"],
    ["hoursOilConnectorLight1000ppm", "Annual Operating Hours"],
    ["oilConnectorLightUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursOilConnectorLightUpTo10000ppm", "Annual Operating Hours"],
    ["oilConnectorLightAbove10000ppm", "# > 10000ppm"],
    ["hoursOilConnectorLightAbove10000ppm", "Annual Operating Hours"],
  ];

  const pumpSealsLightLabels = [
    ["pumpSealsLight1000ppm", "# 0 - 1000ppm"],
    ["hoursPumpSealsLight1000ppm", "Annual Operating Hours"],
    ["pumpSealsLightUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursPumpSealsLightUpTo10000ppm", "Annual Operating Hours"],
    ["pumpSealsLightAbove10000ppm", "# > 10000ppm"],
    ["hoursPumpSealsLightAbove10000ppm", "Annual Operating Hours"],
  ];

  const pressureReliefGasLabels = [
    ["pressureReliefGas1000ppm", "# 0 - 1000ppm"],
    ["hoursPressureReliefGas1000ppm", "Annual Operating Hours"],
    ["pressureReliefGasUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursPressureReliefGasUpTo10000ppm", "Annual Operating Hours"],
    ["pressureReliefGasAbove10000ppm", "# > 10000ppm"],
    ["hoursPressureReliefGasAbove10000ppm", "Annual Operating Hours"],
  ];

  const openEndedLinesLabels = [
    ["openEndedLines1000ppm", "# 0 - 1000ppm"],
    ["hoursOpenEndedLines1000ppm", "Annual Operating Hours"],
    ["openEndedLinesUpTo10000ppm", "# 1000-10000ppm"],
    ["hoursOpenEndedLinesUpTo10000ppm", "Annual Operating Hours"],
    ["openEndedLinesAbove10000ppm", "# > 10000ppm"],
    ["hoursOpenEndedLinesAbove10000ppm", "Annual Operating Hours"],
  ];

  // Hooks and States
  const defaultHours = "7200";
  const [gasValveSweet, setGasValveSweet] = useState({
    gasValve: {
      gasValveSweet1000ppm: { value: "", unit: "" },
      hoursGasValveSweet1000ppm: { value: defaultHours, unit: "" },
      gasValveSweetUpTo10000ppm: { value: "", unit: "" },
      hoursGasValveSweetUpTo10000ppm: { value: defaultHours, unit: "" },
      gasValveSweetAbove10000ppm: { value: "", unit: "" },
      hoursGasValveSweetAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [gasValveSour, setGasValveSour] = useState({
    gasValve: {
      gasValveSour1000ppm: { value: "", unit: "" },
      hoursGasValveSour1000ppm: { value: defaultHours, unit: "" },
      gasValveSourUpTo10000ppm: { value: "", unit: "" },
      hoursGasValveSourUpTo10000ppm: { value: defaultHours, unit: "" },
      gasValveSourAbove10000ppm: { value: "", unit: "" },
      hoursGasValveSourAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [gasValveLight, setGasValveLight] = useState({
    gasValve: {
      gasValveLight1000ppm: { value: "", unit: "" },
      hoursGasValveLight1000ppm: { value: defaultHours, unit: "" },
      gasValveLightUpTo10000ppm: { value: "", unit: "" },
      hoursGasValveLightUpTo10000ppm: { value: defaultHours, unit: "" },
      gasValveLightAbove10000ppm: { value: "", unit: "" },
      hoursGasValveLightAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [oilValveGas, setOilValveGas] = useState({
    oilValve: {
      oilValveGas1000ppm: { value: "", unit: "" },
      hoursOilValveGas1000ppm: { value: defaultHours, unit: "" },
      oilValveGasUpTo10000ppm: { value: "", unit: "" },
      hoursOilValveGasUpTo10000ppm: { value: defaultHours, unit: "" },
      oilValveGasAbove10000ppm: { value: "", unit: "" },
      hoursOilValveGasAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [oilValveLight, setOilValveLight] = useState({
    oilValve: {
      oilValveLight1000ppm: { value: "", unit: "" },
      hoursOilValveLight1000ppm: { value: defaultHours, unit: "" },
      oilValveLightUpTo10000ppm: { value: "", unit: "" },
      hoursOilValveLightUpTo10000ppm: { value: defaultHours, unit: "" },
      oilValveLightAbove10000ppm: { value: "", unit: "" },
      hoursOilValveLightAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [gasConnectorSweet, setGasConnectorSweet] = useState({
    gasConnector: {
      gasConnectorSweet1000ppm: { value: "", unit: "" },
      hoursGasConnectorSweet1000ppm: { value: defaultHours, unit: "" },
      gasConnectorSweetUpTo10000ppm: { value: "", unit: "" },
      hoursGasConnectorSweetUpTo10000ppm: { value: defaultHours, unit: "" },
      gasConnectorSweetAbove10000ppm: { value: "", unit: "" },
      hoursGasConnectorSweetAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  const [gasConnectorSour, setGasConnectorSour] = useState({
    gasConnector: {
      gasConnectorSour1000ppm: { value: "", unit: "" },
      hoursGasConnectorSour1000ppm: { value: defaultHours, unit: "" },
      gasConnectorSourUpTo10000ppm: { value: "", unit: "" },
      hoursGasConnectorSourUpTo10000ppm: { value: defaultHours, unit: "" },
      gasConnectorSourAbove10000ppm: { value: "", unit: "" },
      hoursGasConnectorSourAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  const [gasConnectorLight, setGasConnectorLight] = useState({
    gasConnector: {
      gasConnectorLight1000ppm: { value: "", unit: "" },
      hoursGasConnectorLight1000ppm: { value: defaultHours, unit: "" },
      gasConnectorLightUpTo10000ppm: { value: "", unit: "" },
      hoursGasConnectorLightUpTo10000ppm: { value: defaultHours, unit: "" },
      gasConnectorLightAbove10000ppm: { value: "", unit: "" },
      hoursGasConnectorLightAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  const [oilConnectorGas, setOilConnectorGas] = useState({
    oilConnector: {
      oilConnectorGas1000ppm: { value: "", unit: "" },
      hoursOilConnectorGas1000ppm: { value: defaultHours, unit: "" },
      oilConnectorGasUpTo10000ppm: { value: "", unit: "" },
      hoursOilConnectorGasUpTo10000ppm: { value: defaultHours, unit: "" },
      oilConnectorGasAbove10000ppm: { value: "", unit: "" },
      hoursOilConnectorGasAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [oilConnectorLight, setOilConnectorLight] = useState({
    oilConnector: {
      oilConnectorLight1000ppm: { value: "", unit: "" },
      hoursOilConnectorLight1000ppm: { value: defaultHours, unit: "" },
      oilConnectorLightUpTo10000ppm: { value: "", unit: "" },
      hoursOilConnectorLightUpTo10000ppm: { value: defaultHours, unit: "" },
      oilConnectorLightAbove10000ppm: { value: "", unit: "" },
      hoursOilConnectorLightAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  const [pumpSealsLight, setPumpSealsLight] = useState({
    pumpSeals: {
      pumpSealsLight1000ppm: { value: "", unit: "" },
      hoursPumpSealsLight1000ppm: { value: defaultHours, unit: "" },
      pumpSealsLightUpTo10000ppm: { value: "", unit: "" },
      hoursPumpSealsLightUpTo10000ppm: { value: defaultHours, unit: "" },
      pumpSealsLightAbove10000ppm: { value: "", unit: "" },
      hoursPumpSealsLightAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  const [pressureReliefGas, setPressureReliefGas] = useState({
    pressureRelief: {
      pressureReliefGas1000ppm: { value: "", unit: "" },
      hoursPressureReliefGas1000ppm: { value: defaultHours, unit: "" },
      pressureReliefGasUpTo10000ppm: { value: "", unit: "" },
      hoursPressureReliefGasUpTo10000ppm: { value: defaultHours, unit: "" },
      pressureReliefGasAbove10000ppm: { value: "", unit: "" },
      hoursPressureReliefGasAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });
  const [openEndedLines, setOpenEndedLines] = useState({
    openEndedLines: {
      openEndedLines1000ppm: { value: "", unit: "" },
      hoursOpenEndedLines1000ppm: { value: defaultHours, unit: "" },
      openEndedLinesUpTo10000ppm: { value: "", unit: "" },
      hoursOpenEndedLinesUpTo10000ppm: { value: defaultHours, unit: "" },
      openEndedLinesAbove10000ppm: { value: "", unit: "" },
      hoursOpenEndedLinesAbove10000ppm: { value: defaultHours, unit: "" },
    },
  });

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      // Gas Sweet
      const gasValveObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.gasValve;
      const gasValveObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.gasValve;

      setGasValveSweet({
        gasValve: {
          gasValveSweet1000ppm: {
            value: gasValveObjValues.sweet.upTo1000ppm,
            unit: "",
          },
          hoursGasValveSweet1000ppm: {
            value: gasValveObjHours.sweet.upTo1000ppm,
            unit: "",
          },
          gasValveSweetUpTo10000ppm: {
            value: gasValveObjValues.sweet.upTo10000ppm,
            unit: "",
          },
          hoursGasValveSweetUpTo10000ppm: {
            value: gasValveObjHours.sweet.upTo10000ppm,
            unit: "",
          },
          gasValveSweetAbove10000ppm: {
            value: gasValveObjValues.sweet.above10000ppm,
            unit: "",
          },
          hoursGasValveSweetAbove10000ppm: {
            value: gasValveObjHours.sweet.upTo10000ppm,
            unit: "",
          },
        },
      });

      //Gas Sour
      setGasValveSour({
        gasValve: {
          gasValveSour1000ppm: {
            value: gasValveObjValues.sour.upTo1000ppm,
            unit: "",
          },
          hoursGasValveSour1000ppm: {
            value: gasValveObjHours.sour.upTo1000ppm,
            unit: "",
          },
          gasValveSourUpTo10000ppm: {
            value: gasValveObjValues.sour.upTo10000ppm,
            unit: "",
          },
          hoursGasValveSourUpTo10000ppm: {
            value: gasValveObjHours.sour.upTo10000ppm,
            unit: "",
          },
          gasValveSourAbove10000ppm: {
            value: gasValveObjValues.sour.above10000ppm,
            unit: "",
          },
          hoursGasValveSourAbove10000ppm: {
            value: gasValveObjHours.sour.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Gas Light
      setGasValveLight({
        gasValve: {
          gasValveLight1000ppm: {
            value: gasValveObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursGasValveLight1000ppm: {
            value: gasValveObjHours.light.upTo1000ppm,
            unit: "",
          },
          gasValveLightUpTo10000ppm: {
            value: gasValveObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursGasValveLightUpTo10000ppm: {
            value: gasValveObjHours.light.upTo10000ppm,
            unit: "",
          },
          gasValveLightAbove10000ppm: {
            value: gasValveObjValues.light.above10000ppm,
            unit: "",
          },
          hoursGasValveLightAbove10000ppm: {
            value: gasValveObjHours.light.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Oil Light
      const oilValveObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.oilValve;
      const oilValveObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.oilValve;
      setOilValveLight({
        oilValve: {
          oilValveLight1000ppm: {
            value: oilValveObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursOilValveLight1000ppm: {
            value: oilValveObjHours.light.upTo1000ppm,
            unit: "",
          },
          oilValveLightUpTo10000ppm: {
            value: oilValveObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursOilValveLightUpTo10000ppm: {
            value: oilValveObjHours.light.upTo10000ppm,
            unit: "",
          },
          oilValveLightAbove10000ppm: {
            value: oilValveObjValues.light.above10000ppm,
            unit: "",
          },
          hoursOilValveLightAbove10000ppm: {
            value: oilValveObjHours.light.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Oil Gas
      setOilValveGas({
        oilValve: {
          oilValveGas1000ppm: {
            value: oilValveObjValues.gas.upTo1000ppm,
            unit: "",
          },
          hoursOilValveGas1000ppm: {
            value: oilValveObjHours.gas.upTo1000ppm,
            unit: "",
          },
          oilValveGasUpTo10000ppm: {
            value: oilValveObjValues.gas.upTo10000ppm,
            unit: "",
          },
          hoursOilValveGasUpTo10000ppm: {
            value: oilValveObjHours.gas.upTo10000ppm,
            unit: "",
          },
          oilValveGasAbove10000ppm: {
            value: oilValveObjValues.gas.above10000ppm,
            unit: "",
          },
          hoursOilValveGasAbove10000ppm: {
            value: oilValveObjHours.gas.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Gas Connector
      const gasConnectorObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.gasConnector;
      const gasConnectorObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.gasConnector;

      setGasConnectorSweet({
        gasConnector: {
          gasConnectorSweet1000ppm: {
            value: gasConnectorObjValues.sweet.upTo1000ppm,
            unit: "",
          },
          hoursGasConnectorSweet1000ppm: {
            value: gasConnectorObjHours.sweet.upTo1000ppm,
            unit: "",
          },
          gasConnectorSweetUpTo10000ppm: {
            value: gasConnectorObjValues.sweet.upTo10000ppm,
            unit: "",
          },
          hoursGasConnectorSweetUpTo10000ppm: {
            value: gasConnectorObjHours.sweet.upTo10000ppm,
            unit: "",
          },
          gasConnectorSweetAbove10000ppm: {
            value: gasConnectorObjValues.sweet.above10000ppm,
            unit: "",
          },
          hoursGasConnectorSweetAbove10000ppm: {
            value: gasConnectorObjHours.sweet.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Gas connector sour
      setGasConnectorSour({
        gasConnector: {
          gasConnectorSour1000ppm: {
            value: gasConnectorObjValues.sour.upTo1000ppm,
            unit: "",
          },
          hoursGasConnectorSour1000ppm: {
            value: gasConnectorObjHours.sour.upTo1000ppm,
            unit: "",
          },
          gasConnectorSourUpTo10000ppm: {
            value: gasConnectorObjValues.sour.upTo10000ppm,
            unit: "",
          },
          hoursGasConnectorSourUpTo10000ppm: {
            value: gasConnectorObjHours.sour.upTo10000ppm,
            unit: "",
          },
          gasConnectorSourAbove10000ppm: {
            value: gasConnectorObjValues.sour.above10000ppm,
            unit: "",
          },
          hoursGasConnectorSourAbove10000ppm: {
            value: gasConnectorObjHours.sour.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Gas Connector Light
      setGasConnectorLight({
        gasConnector: {
          gasConnectorLight1000ppm: {
            value: gasConnectorObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursGasConnectorLight1000ppm: {
            value: gasConnectorObjHours.light.upTo1000ppm,
            unit: "",
          },
          gasConnectorLightUpTo10000ppm: {
            value: gasConnectorObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursGasConnectorLightUpTo10000ppm: {
            value: gasConnectorObjHours.light.upTo10000ppm,
            unit: "",
          },
          gasConnectorLightAbove10000ppm: {
            value: gasConnectorObjValues.light.above10000ppm,
            unit: "",
          },
          hoursGasConnectorLightAbove10000ppm: {
            value: gasConnectorObjHours.light.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Oil Valve light
      setOilValveLight({
        oilValve: {
          oilValveLight1000ppm: {
            value: oilValveObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursOilValveLight1000ppm: {
            value: oilValveObjHours.light.upTo1000ppm,
            unit: "",
          },
          oilValveLightUpTo10000ppm: {
            value: oilValveObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursOilValveLightUpTo10000ppm: {
            value: oilValveObjHours.light.upTo10000ppm,
            unit: "",
          },
          oilValveLightAbove10000ppm: {
            value: oilValveObjValues.light.above10000ppm,
            unit: "",
          },
          hoursOilValveLightAbove10000ppm: {
            value: oilValveObjHours.light.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Oil Connector

      const oilConnectorObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.oilConnector;
      const oilConnectorObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.oilConnector;
      setOilConnectorGas({
        oilConnector: {
          oilConnectorGas1000ppm: {
            value: oilConnectorObjValues.gas.upTo1000ppm,
            unit: "",
          },
          hoursOilConnectorGas1000ppm: {
            value: oilConnectorObjHours.gas.upTo1000ppm,
            unit: "",
          },
          oilConnectorGasUpTo10000ppm: {
            value: oilConnectorObjValues.gas.upTo10000ppm,
            unit: "",
          },
          hoursOilConnectorGasUpTo10000ppm: {
            value: oilConnectorObjHours.gas.upTo10000ppm,
            unit: "",
          },
          oilConnectorGasAbove10000ppm: {
            value: oilConnectorObjValues.gas.above10000ppm,
            unit: "",
          },
          hoursOilConnectorGasAbove10000ppm: {
            value: oilConnectorObjHours.gas.upTo10000ppm,
            unit: "",
          },
        },
      });

      setOilConnectorLight({
        oilConnector: {
          oilConnectorLight1000ppm: {
            value: oilConnectorObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursOilConnectorLight1000ppm: {
            value: oilConnectorObjHours.light.upTo1000ppm,
            unit: "",
          },
          oilConnectorLightUpTo10000ppm: {
            value: oilConnectorObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursOilConnectorLightUpTo10000ppm: {
            value: oilConnectorObjHours.light.upTo10000ppm,
            unit: "",
          },
          oilConnectorLightAbove10000ppm: {
            value: oilConnectorObjValues.light.above10000ppm,
            unit: "",
          },
          hoursOilConnectorLightAbove10000ppm: {
            value: oilConnectorObjHours.light.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Pump Seals
      const pumpSealsObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.pumpSeals;
      const pumpSealsObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.pumpSeals;
      setPumpSealsLight({
        pumpSeals: {
          pumpSealsLight1000ppm: {
            value: pumpSealsObjValues.light.upTo1000ppm,
            unit: "",
          },
          hoursPumpSealsLight1000ppm: {
            value: pumpSealsObjHours.light.upTo1000ppm,
            unit: "",
          },
          pumpSealsLightUpTo10000ppm: {
            value: pumpSealsObjValues.light.upTo10000ppm,
            unit: "",
          },
          hoursPumpSealsLightUpTo10000ppm: {
            value: pumpSealsObjHours.light.upTo10000ppm,
            unit: "",
          },
          pumpSealsLightAbove10000ppm: {
            value: pumpSealsObjValues.light.above10000ppm,
            unit: "",
          },
          hoursPumpSealsLightAbove10000ppm: {
            value: pumpSealsObjHours.light.above10000ppm,
            unit: "",
          },
        },
      });

      // Pressure Relief
      const pressureReliefObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.pressureRelief;
      const pressureReliefObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.pressureRelief;
      setPressureReliefGas({
        pressureRelief: {
          pressureReliefGas1000ppm: {
            value: pressureReliefObjValues.gas.upTo1000ppm,
            unit: "",
          },
          hoursPressureReliefGas1000ppm: {
            value: pressureReliefObjHours.gas.upTo1000ppm,
            unit: "",
          },
          pressureReliefGasUpTo10000ppm: {
            value: pressureReliefObjValues.gas.upTo10000ppm,
            unit: "",
          },
          hoursPressureReliefGasUpTo10000ppm: {
            value: pressureReliefObjHours.gas.upTo10000ppm,
            unit: "",
          },
          pressureReliefGasAbove10000ppm: {
            value: pressureReliefObjValues.gas.above10000ppm,
            unit: "",
          },
          hoursPressureReliefGasAbove10000ppm: {
            value: pressureReliefObjHours.gas.upTo10000ppm,
            unit: "",
          },
        },
      });

      // Open Ended Lines
      const openEndedLinesObjValues =
        populatedUWI.fugitiveEmissionsPerMonth.coint.openEndedLines;
      const openEndedLinesObjHours =
        populatedUWI.fugitiveEmissionsPerMonth.hours.openEndedLines;
      setOpenEndedLines({
        openEndedLines: {
          openEndedLines1000ppm: {
            value: openEndedLinesObjValues.upTo1000ppm,
            unit: "",
          },
          hoursOpenEndedLines1000ppm: {
            value: openEndedLinesObjHours.upTo1000ppm,
            unit: "",
          },
          openEndedLinesUpTo10000ppm: {
            value: openEndedLinesObjValues.upTo10000ppm,
            unit: "",
          },
          hoursOpenEndedLinesUpTo10000ppm: {
            value: openEndedLinesObjHours.upTo10000ppm,
            unit: "",
          },
          openEndedLinesAbove10000ppm: {
            value: openEndedLinesObjValues.above10000ppm,
            unit: "",
          },
          hoursOpenEndedLinesAbove10000ppm: {
            value: openEndedLinesObjHours.upTo10000ppm,
            unit: "",
          },
        },
      });
    }
  }, [populatedUWI]);

  return {
    gasValveSweet,
    gasValveSour,
    gasValveLight,
    oilValveGas,
    oilValveLight,
    gasConnectorSweet,
    gasConnectorSour,
    gasConnectorLight,
    oilConnectorGas,
    oilConnectorLight,
    pumpSealsLight,
    pressureReliefGas,
    openEndedLines,
    renderFugitiveEmissions: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Fugitive Emissions</Typography>
              <div>
                <PopoverComponent
                  infoText={
                    "Fugitive emissions are defined as the unintentional and undesirable emission, leakage, or discharge of gases or vapors from pressure-containing equipment or facilities, and from components inside an industrial plant such as valves, piping flanges, pumps, storage tanks, compressors, etc."
                  }
                />
              </div>
            </div>
          </AccordionSummary>
          {/* -------------- Inputs ------------- */}
          <AccordionDetails>
            <div className="allInputs">
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Gas Valve"}
                    headers={["Sweet Gas", "Sour Gas", "Light Liquid"]}
                    labels={[
                      gasValveSweetLabels,
                      gasValveSourLabels,
                      gasValveLightLabels,
                    ]}
                    inputs={[gasValveSweet, gasValveSour, gasValveLight]}
                    valueChange={[
                      setGasValveSweet,
                      setGasValveSour,
                      setGasValveLight,
                    ]}
                    gridSize={6}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Oil Valve"}
                    headers={["Gas/Vapour", "Light Liquid"]}
                    labels={[oilValveGasLabels, oilValveLightLabels]}
                    inputs={[oilValveGas, oilValveLight]}
                    valueChange={[setOilValveGas, setOilValveLight]}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Gas Connector"}
                    headers={["Sweet Gas", "Sour Gas", "Light Liquid"]}
                    labels={[
                      gasConnectorSweetLabels,
                      gasConnectorSourLabels,
                      gasConnectorLightLabels,
                    ]}
                    inputs={[
                      gasConnectorSweet,
                      gasConnectorSour,
                      gasConnectorLight,
                    ]}
                    valueChange={[
                      setGasConnectorSweet,
                      setGasConnectorSour,
                      setGasConnectorLight,
                    ]}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Oil Connector"}
                    headers={["Gas/Vapour", "Light Liquid"]}
                    labels={[oilConnectorGasLabels, oilConnectorLightLabels]}
                    inputs={[oilConnectorGas, oilConnectorLight]}
                    valueChange={[setOilConnectorGas, setOilConnectorLight]}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Pump Seals"}
                    headers={["Light Liquid"]}
                    labels={[pumpSealsLightLabels]}
                    inputs={[pumpSealsLight]}
                    valueChange={[setPumpSealsLight]}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Pressure Relief"}
                    headers={["Gas/Vapour"]}
                    labels={[pressureReliefGasLabels]}
                    inputs={[pressureReliefGas]}
                    valueChange={[setPressureReliefGas]}
                  />
                </Grid>
                <Grid item md={12}>
                  <InternalTab
                    accordianTitle={"Open Ended Lines"}
                    headers={["All"]}
                    labels={[openEndedLinesLabels]}
                    inputs={[openEndedLines]}
                    valueChange={[setOpenEndedLines]}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
