import React from 'react'
import { Typography } from '@mui/material'
import "./infoStyles.css"
export default function Info() {
    return (
        <div className='info'>
            <Typography variant="h3">Crbon Labs CrbonScope</Typography>
            <div className='bodyWrapper'>
                <Typography className='bodyText' variant='body1'>CrbonScope puts oil and gas well-specific Carbon Intensity (CI) information at the fingertips of industry. It’s a robust engineering-developed tool to quickly estimate/determine emissions linked to any specific producing well in the WCSB. With CrbonScope you can find the CI of the production from any well.
                    <br /><br />
                    CrbonScope combines publicly available data with oil and gas development and
                    production expertise to provide engineering and marketing individuals alike with actionable insight, helping prioritize their effort to reduce emissions and/or support commercial negotiations.</Typography>
            </div>

        </div>
    )
}
