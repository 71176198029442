import React, { useState, useEffect } from 'react'
import { GoogleMap, Marker } from "@react-google-maps/api"
import { FormHelperText, TextField, InputLabel, Select, MenuItem, Grid, Button, } from '@mui/material'

import PopoverComponent from './PopoverComponent'

import { grabCoordinates, getUWIs, getEntry } from '../../../APIs/databaseAPI'

// Styles
import "./styles/InputStyles.css"





/**
 * @param {Event Handler} onChange An onChange event that handles changing of text.
 * @param {String} inputId The name of the ID for the Textfield and htmlFor.
 * @param {String} label The name of the label for the input.
 * @param {String} inputType The value for the 'type' property of a TextField. 
 * @return Rendered label and input for an open text field. 
 */
export default function NumberInputForMap({ onTextChange, inputId, label, inputType, size, textValue, onSelectChange, selectItems, selectDefaultValue, index, infoIconText, selectLabel, gridSize, disabled, uwiValueChange, mapHandler, isLoaded, populatedUWISetter, latSetter, lngSetter }) {

    /**
     * 
     * @param {Number} gridSize A number that determinesthe grid size for the inputs.
     * Default is 3 if there is not grid size.
     * @returns An integer representing how many columns should be used for the inputs. (Ex. column of 3)
     */
    const setGridSize = (gridSize, inputType) => {
        if (gridSize) {
            return gridSize
        } else {
            return 3
        }
    }

    /**
     * 
     * @param {Number} start The starting integer for menu items
     * @param {*} end  The end point for menuitems
     * @param {*} name Used for the key of the menu items
     * @returns An Array of unique MenuItem components 
     */
    const createNumberMenuItems = (start, end, name) => {
        let elements = [];
        for (let i = start; i <= end; i++) {
            elements.push(<MenuItem
                key={name + i}
                value={i < 10 ? "0" + i : i}
            >
                {i}
            </MenuItem>)
        }
        return elements
    }

    /**
     * 
     * @returns An array of MenuItem components for the Meridian drop-down.
     */
    const createMeridianMenuItems = () => {
        let meridians = ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "E1", "E2", "COAST"]
        let elements = []
        meridians.map(function (meridian, index) {
            elements.push(
                <MenuItem
                    key={"meridian" + index}
                    value={meridian}
                >
                    {meridian}
                </MenuItem >
            )
        })
        return elements
    }
    /**
     * 
     * @returns An array of MenuItem components for the Event drop-down.
     */
    const createEventMenuItems = () => {

        let elements = []
        for (let i = 0; i <= 9; i++) {
            if (i !== 1) {
                elements.push(
                    <MenuItem
                        key={"event" + i}
                        value={"0" + i}
                    >
                        {"0" + i}
                    </MenuItem >
                )
            }

        }
        return elements
    }


    // Restyling the dropdown.
    const ITEM_HEIGHT = 200;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT,
                width: 80,
            },
        },
    };

    // Dropdown Hooks.
    const [dls, setDls] = useState("100")
    const [lsd, setLsd] = useState("")
    const [section, setSection] = useState("")
    const [township, setTownship] = useState("")
    const [range, setRange] = useState("")
    const [meridian, setMeridian] = useState("")
    const [uwi, setUwi] = useState("")
    const [event, setEvent] = useState("")

    /**
     * 
     * @param {Event} event The event object holding the new value for the drop down.
     * @param {String} input A string determining which drop down needs changing.
     */
    const handleSelectChange = (event, input) => {
        const newSelection = event.target.value
        let uwiString
        switch (input) {
            case "dls":
                setDls(newSelection)
                break;
            case "lsd":
                setLsd(newSelection)
                break;
            case "section":
                setSection(newSelection)
                break;
            case "township":
                setTownship(newSelection)
                break;
            case "range":
                setRange(newSelection)
                break;
            case "meridian":
                setMeridian(newSelection)
                break;
            case "event":
                setEvent(newSelection)
                break;
            default:
                console.log("No matching case for changing select box.")
        }
    }

    // Creates the proper UWI format string for the map.
    useEffect(() => {
        const uwiString = dls + " " + lsd + "-" + section + "-" + township + "-" + range + meridian + " " + event
        uwiValueChange(
            uwiString
        )

    }, [dls, event, lsd, meridian, range, section, township, uwiValueChange])

    // Creates proper UWI format string for displaying on the fly in NumberInput scope.
    useEffect(() => {

        const uwiString = dls + " " + lsd + "-" + section + "-" + township + "-" + range + meridian + " " + event
        if (dls && lsd && section && township && range && meridian && event) {
            setUwi(uwiString)
        } else {
            setUwi("")
        }

    }, [dls, event, lsd, meridian, range, section, township])

    const [marker, setMarker] = useState()
    const handleMap = () => {
        grabCoordinates(uwi,
            function (response) {

                const thisLat = response[0]
                const thisLng = response[1]
                console.log(thisLat)
                console.log(thisLng)
                const location = { lat: thisLat, lng: thisLng }
                setMarker(location)
                setCenter(location)
                uwiValueChange({
                    uwi: uwi,
                    lat: thisLat,
                    lng: thisLng
                })
            })
    }

    const [map, setMap] = useState(null);
    const handleMapLoad = (map) => {
        setMap(map);
    };
    const [center, setCenter] = useState({ lat: 44, lng: -80 })

    // Select box for previous Entries
    const [existingUWIs, setExistingUWIs] = useState()
    useEffect(() => {
        getUWIs(function (response) {
            setExistingUWIs(response)
        })
    }, [])
    const [selectUWI, setSelectUWI] = useState("Previously selected UWIs")
    const [entryUWI, setEntryUWI] = useState("")
    // Changing the UWI select boxes to the pre-populated
    useEffect(() => {
        if (entryUWI) {
            const [thisDls, thisLsd, thisSection, thisTownship, thisRange, thisMeridian, thisEvent] = entryUWI.split(/[-\s]+|(?=[WEC])/).filter(Boolean);

            setDls(thisDls)
            setLsd(thisLsd)
            setSection(thisSection)
            setTownship(thisTownship)
            setRange(thisRange)
            setMeridian(thisMeridian)
            setEvent(thisEvent)



        }
    }, [entryUWI])


    const [lat, setLat] = useState("")
    const [lng, setLng] = useState("")

    const handleUWISelectChange = async (e) => {
        const newValue = e.target.value
        getEntry(newValue, function (response) {
            setEntryUWI(response.uwi)
            populatedUWISetter(response)
            setLat(response.wellLocation.lat)
            setLng(response.wellLocation.lng)
        })
        setSelectUWI(newValue)
    }



    const handleCoordinateChange = (event, updater) => {
        const newValue = event.target.value
        updater(newValue)
    }

    useEffect(() => {
        latSetter(lat)
    }, [lat, latSetter])

    useEffect(() => {
        lngSetter(lng)
    }, [lng, lngSetter])





    return (

        <Grid className="inputGridItem" item md={setGridSize(gridSize, inputType)} lg={12} >
            <div className='flexWrapper'>
                <InputLabel

                    htmlFor={inputId}
                    className='numberFormLabels'
                >{label} {label === "UWI Surface:" ? <span style={{ fontWeight: "600" }}>{uwi}</span> : null}</InputLabel>
                {infoIconText ? <PopoverComponent infoText={infoIconText} /> : ""}
            </div>
            {/*------------------- UWI Drop-down ------------------- */}
            {index === 0 ?
                // If input is the select boxes for UWI.
                <Grid container spacing={1}>

                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            DLS
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={dls}
                            id="DLSSelectInput"
                            className="uwiLabels"
                            value={dls}
                            onChange={(e) => handleSelectChange(e, "dls")}
                        >

                            <MenuItem
                                value=""
                            >
                            </MenuItem>
                            {createNumberMenuItems(100, 109, "DLS")}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            LSD
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={lsd}
                            id="LSDSelectInput"
                            className="uwiLabels"
                            value={lsd}
                            onChange={(e) => handleSelectChange(e, "lsd")}
                        >
                            <MenuItem
                                value=""
                            >
                            </MenuItem>
                            {createNumberMenuItems(1, 16, "LSD")}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            Section
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={section}
                            className="uwiLabels"
                            id="sectionSelectInput"
                            value={section}
                            onChange={(e) => handleSelectChange(e, "section")}
                        >
                            <MenuItem
                                value=""
                            >

                            </MenuItem>
                            {createNumberMenuItems(1, 36, "section")}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            Township
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={township}
                            className="uwiLabels"
                            id="townshipSelectInput"
                            value={township}
                            onChange={(e) => handleSelectChange(e, "township")}
                        >
                            <MenuItem
                                value=""
                            >

                            </MenuItem>
                            {createNumberMenuItems(1, 127, "township")}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            Range
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={range}
                            className="uwiLabels"
                            id="rangeSelectInput"
                            value={range}
                            onChange={(e) => handleSelectChange(e, "range")}
                        >
                            <MenuItem
                                value=""
                            >

                            </MenuItem>
                            {createNumberMenuItems(1, 34, "Range")}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            Meridian
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={meridian}
                            className="uwiLabels"
                            id="meridianSelectInput"
                            value={meridian}
                            onChange={(e) => handleSelectChange(e, "meridian")}
                        >
                            <MenuItem
                                value=""
                            >

                            </MenuItem>
                            {createMeridianMenuItems()}
                        </Select>
                    </Grid>

                    <Grid item lg={1.5} md={12} sm={12} xs={12}>
                        <InputLabel
                            className='selectBoxLabels'
                        >
                            Event
                        </InputLabel>

                        <Select
                            MenuProps={MenuProps}
                            size="small"
                            defaultValue={event}
                            id="DSL2SelectInput"
                            className="uwiLabels"
                            value={event}
                            onChange={(e) => handleSelectChange(e, "event")}
                        >
                            <MenuItem
                                value=""
                            >
                            </MenuItem>
                            {createEventMenuItems()}
                        </Select>
                    </Grid>
                    <Grid item lg={1.5} md={12} sm={12} xs={12}>
                        <Button size='small' style={{ backgroundColor: "#ff9fa7", marginTop: "22px", height: "40px", width: "100%" }} variant='contained' onClick={handleMap}>Locate</Button>
                    </Grid>

                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div className='coordinateContainer'>
                            <div>
                                <div className=''>
                                    <InputLabel

                                        htmlFor={"latitudeId"}
                                        className='numberFormLabels'
                                    >Latitude:</InputLabel>

                                </div>
                                <div className=''>
                                    <TextField
                                        id={"latitudeId"}
                                        size='small'
                                        className='numberFormInputs'
                                        value={lat}
                                        type='number'
                                        onChange={(e) => handleCoordinateChange(e, setLat)}
                                    />
                                </div>
                            </div>
                            <div style={{ marginLeft: "3%" }}>
                                <div className=''>
                                    <InputLabel
                                        htmlFor={"longitudeId"}
                                        className='numberFormLabels'
                                    >Longitude:</InputLabel>

                                </div>
                                <div className=''>
                                    <TextField
                                        id={"longitudeId"}
                                        size='small'
                                        value={lng}
                                        className='numberFormInputs'
                                        type='number'
                                        onChange={(e) => handleCoordinateChange(e, setLng)}

                                    />
                                </div>
                                <div>

                                </div>

                            </div>


                        </div>

                    </Grid>

                    {/* Previously Selected UWI */}
                    <Grid item lg={5} md={0} sm={0} xs={0} />
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                        <div className='flexWrapper'>
                            <InputLabel

                                htmlFor={"latitudeId"}
                                className='numberFormLabels'
                            >Populate Facility Data:</InputLabel>

                        </div>
                        <div className='flexWrapper'>
                            <Select
                                size='small'
                                onChange={(e) => handleUWISelectChange(e)}
                                value={selectUWI}
                            >

                                <MenuItem

                                    value={"Previously selected UWIs"}>
                                    Previously selected UWIs
                                </MenuItem>

                                {existingUWIs ? existingUWIs.map(function (thisExistingUwi, index) {
                                    return <MenuItem
                                        key={"menuItem" + index}
                                        value={thisExistingUwi}>
                                        {thisExistingUwi}
                                    </MenuItem>
                                }) : null}
                            </Select>
                        </div>



                    </Grid>


                    {isLoaded ?
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <GoogleMap
                                zoom={10}
                                mapTypeId='satellite'
                                center={center} mapContainerClassName="mapContainer"
                                onLoad={handleMapLoad}
                                options={{
                                    mapTypeId: window.google.maps.MapTypeId.HYBRID
                                }}

                            >
                                {marker && (<Marker position={marker} />)}
                            </GoogleMap>
                        </Grid>
                        : <div>Loading...</div>}
                    {/*------------------- END of UWI Drop-down ------------------- */}
                </Grid>
                // ---------------Normal Input fields --------------
                : <div className='flexWrapper'>
                    <TextField
                        id={inputId + "Id"}
                        className='numberFormInputs'
                        value={textValue}
                        type={inputType ? inputType : 'number'}
                        onChange={onTextChange}
                        size={size}
                        required={true}
                    />
                    {selectItems ? <div><Select
                        size='small'
                        className='selectFormInputs'
                        defaultValue={selectDefaultValue}
                        onChange={onSelectChange}
                        id={inputId + "SelectId"}

                    >
                        {selectItems.map(function (item, index) {
                            return (
                                <MenuItem
                                    key={item + index}
                                    value={selectItems[index]}
                                >
                                    {item}
                                </MenuItem>)
                        })}
                    </Select>
                        <FormHelperText style={{ textAlign: "center" }}>{selectLabel}</FormHelperText>
                    </div>
                        : ""}
                </div>
            }




        </Grid >
    )
}
function Map({ coordinates }) {
    const [markerPosition, setMarkerPosition] = useState(coordinates)

    useEffect(() => {
        setMarkerPosition(coordinates)
    }, [coordinates])

    return <GoogleMap zoom={10} center={coordinates} mapContainerClassName="mapContainer">
        {coordinates ? <Marker position={markerPosition
        }></Marker> : null}


    </GoogleMap>
}