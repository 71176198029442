import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../AccordianStyles.css";

export default function TransportationTab() {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)

  //Input States
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");

  /**
   * @description - Handles the changing of state of the inputs.
   * @param {String} input The name of the input that is being changed.
   * @param {Event Function} event The event that triggered this function.
   */
  const handleTextChange = (input, event) => {
    const text = event.target.value;
    if (input === "first") {
      setFirst(text);
    } else if (input === "second") {
      setSecond(text);
    }
  };

  return {
    first,
    second,
    renderTransporation: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6">Transportation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              {/* First input */}
              <Typography component={"span"}>
                <InputLabel htmlFor="firstInput" className="formLabels">
                  First:
                </InputLabel>
                <TextField
                  id="firstInput"
                  className="formInputs"
                  value={first}
                  onChange={(e) => handleTextChange("first", e)}
                  type="number"
                />
              </Typography>
              {/* Second input */}
              <Typography component={"span"}>
                <InputLabel htmlFor="secondInput" className="formLabels">
                  Second:
                </InputLabel>
                <TextField
                  id="secondInput"
                  className="formInputs"
                  value={second}
                  onChange={(e) => handleTextChange("second", e)}
                  type="number"
                />
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
