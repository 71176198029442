import React, { useState, useCallback, useEffect } from "react";

import { Button, TextField, Grid, InputLabel, Card } from "@mui/material";

//APIs
import { sendInputs, saveSendPdfEmail } from "../../APIs/databaseAPI";

// Accordian Tabs
import InformationTab from "./AccordianTabs/InformationTab";
import BasicWellInfoTab from "./AccordianTabs/BasicWellInfo";
import TransportationTab from "./AccordianTabs/TransportationTab";
import ReservoirTab from "./AccordianTabs/ReservoirTab";
import EmissionSourceTab from "./AccordianTabs/EmissionSourceTab";
import FuelConsumptionTab from "./AccordianTabs/FuelConsumptionTab";
import FlareVentTab from "./AccordianTabs/FlareVentTab";
import FugitiveEmissionsTab from "./AccordianTabs/FugitiveEmissionsTab";
import WellEmissionSourceTab from "./AccordianTabs/WellEmissionSourceTab";

// Styles
import "./AccordianStyles.css";

//Components
import RequirementAlert from "./InputComponents.js/RequirementAlert";

export default function MainAccordian() {
  // Deconstructed tab data from Components
  const [populatedUWI, setPopulatedUWI] = useState("");
  const { renderInformation, information, infoRender, uwi, setInformation } =
    InformationTab(populatedUWI);

  const { wellLocation, isLoaded, facility, renderBasicWellInformation } =
    BasicWellInfoTab(setPopulatedUWI, populatedUWI);

  const { renderTransporation, first, second } = TransportationTab();

  const {
    years,
    oilWell,
    gasWell,
    composition,
    selectedWellOption,
    remainingReserves,
    renderReservoir,
  } = ReservoirTab(populatedUWI);

  const {
    tankVentingEmissions,
    hydrocarbonLiquidLosses,
    solidDesiccantDehydrators,
    pigTrapOpeningsAndPurges,
    engineOrTurbineStarts,
    pneumaticInstruments,
    pneumaticPumps,
    compressorSeals,
    glycolDehydrators,
    renderEmission,
  } = EmissionSourceTab(populatedUWI);
  const { plant, renderFuelConsumption } = FuelConsumptionTab(populatedUWI);

  const { flareVent, plantVol, renderFlareVent } = FlareVentTab(populatedUWI);

  const { wellEmissionSources, render, renderWellEmissions } =
    WellEmissionSourceTab(populatedUWI);
  const {
    gasValveSweet,
    gasValveSour,
    gasValveLight,
    oilValveGas,
    oilValveLight,
    gasConnectorSweet,
    gasConnectorSour,
    gasConnectorLight,
    oilConnectorGas,
    oilConnectorLight,
    pumpSealsLight,
    pressureReliefGas,
    openEndedLines,
    renderFugitiveEmissions,
  } = FugitiveEmissionsTab(populatedUWI);

  // Result Hooks
  const [result1, setResult1] = useState(0);
  const [result2, setResult2] = useState(0);
  const [result3, setResult3] = useState(0);
  const [isFormCalculated, setIsFormCalculated] = useState(false);

  /**
   * @description Console.logs the sum of the first and second number in the Transportationm tab
   */

  const inputs = {
    information: information,
    wellLocation,
    facility: facility,
    reservoir: {
      years,
      oilWell,
      gasWell,
      composition,
      selectedWellOption,
      remainingReserves,
    },
    flareVent: {
      flareVent: flareVent,
      plantVol: plantVol,
    },
    fuelConsumption: {
      plant: plant,
    },

    emissionSource: {
      tankVentingEmissions: tankVentingEmissions,
      hydrocarbonLiquidLosses: hydrocarbonLiquidLosses,
      solidDesiccantDehydrators: solidDesiccantDehydrators,
      pigTrapOpeningsAndPurges: pigTrapOpeningsAndPurges,
      engineOrTurbineStarts: engineOrTurbineStarts,
      pneumaticInstruments: pneumaticInstruments,
      pneumaticPumps: pneumaticPumps,
      compressorSeals: compressorSeals,
      glycolDehydrators: glycolDehydrators,
    },
    wellEmissionSource: {
      wellEmissionSources: wellEmissionSources,
    },
    fugitiveEmissions: {
      gasValveSweet: gasValveSweet,
      gasValveSour: gasValveSour,
      gasValveLight: gasValveLight,
      oilValveGas: oilValveGas,
      oilValveLight: oilValveLight,
      gasConnectorSweet: gasConnectorSweet,
      gasConnectorSour: gasConnectorSour,
      gasConnectorLight: gasConnectorLight,
      oilConnectorGas: oilConnectorGas,
      oilConnectorLight: oilConnectorLight,
      pumpSealsLight: pumpSealsLight,
      pressureReliefGas: pressureReliefGas,
      openEndedLines: openEndedLines,
    },
  };

  //Save to DB and send PDF report email
  const pdfEmail = (email) => {
    saveSendPdfEmail(inputs, email, function (response) {
      console.log(response);
    });
  };

  //Validation
  const checkRequired = async () => {
    let informationRequirements = [];
    let reservoirRequirements = [];
    let passRequirements = true;

    const name = information.information.name.value;
    const email = information.information.email.value;
    const remainingYears = years.years.years.value;
    const oilRate = oilWell.oilWell.oilRate.value;
    const gasRate = gasWell.gasWell.gasRate.value;
    const methaneMol = composition.composition.methaneMol.value;

    if (!name || !email) {
      informationRequirements.push("Basic Information");
      passRequirements = false;
      if (!name) {
        informationRequirements.push("Name");
      }
      if (!email) {
        informationRequirements.push("Email");
      }
    }

    if (selectedWellOption === "Gas") {
      console.log("Gas choice");
      if (!gasRate || !remainingYears || !methaneMol) {
        reservoirRequirements.push("Pool/Reservoir");
        passRequirements = false;
        if (!remainingYears) {
          reservoirRequirements.push("Remaining Producible Years");
        }
        if (!gasRate) {
          reservoirRequirements.push("Daily Gas Rate");
        }
        if (!methaneMol) {
          reservoirRequirements.push("Methane (CH4) Mol %");
        }
      } else {
        setReservoirRequiredMessage("");
      }
    } else if (selectedWellOption === "Oil") {
      console.log("Oil choice");
      if (!oilRate || !remainingYears || !methaneMol) {
        reservoirRequirements.push("Pool/Reservoir");
        passRequirements = false;
        if (!remainingYears) {
          reservoirRequirements.push("Remaining Producible Years");
        }
        if (!oilRate) {
          reservoirRequirements.push("Daily Oil Rate");
        }
        if (!methaneMol) {
          reservoirRequirements.push("Methane (CH4) Mol %");
        }
      } else {
        setReservoirRequiredMessage("");
      }
    }

    if (informationRequirements.length > 0) {
      await setInformationRequiredMessage(informationRequirements);
      await setInformationAlertOpen(true);
    } else {
      setInformationAlertOpen(false);
    }
    if (reservoirRequirements.length > 0) {
      await setReservoirRequiredMessage(reservoirRequirements);
      await setReservoirAlertOpen(true);
    } else {
      setReservoirAlertOpen(false);
    }
    return passRequirements;
  };
  // Front-end quick calculation.
  const testDisplay = async () => {
    const passRequirements = await checkRequired();

    if (passRequirements) {
      handleInformationAlertClose(false);
      handleReservoirAlertClose(false);
      sendInputs(inputs, function (response) {
        setResult1(response[0]);
        setResult2(response[1]);
        setResult3(response[2] ? response[2] : 0);
      });
    }
  };

  /**
   * @description Checks which tab is needed and returns the component.
   * @Dependencies Only contain elements that need to change on the fly within a tab. Such as toggle button change.
   * @param {String} info - The name of the tab that needs to be rendered.
   * @returns The Component tab that is rendered.
   */

  const renderInfo = useCallback(
    (info) => {
      switch (info) {
        case "information":
          return renderInformation;
        case "well":
          return renderBasicWellInformation;
        case "transporation":
          return renderTransporation;
        case "reservoir":
          return renderReservoir;
        case "fuelConsumption":
          return renderFuelConsumption;
        case "emission":
          return renderEmission;
        case "flareVent":
          return renderFlareVent;
        case "fugitiveEmissions":
          return renderFugitiveEmissions;
        case "wellEmission":
          return renderWellEmissions;
        default:
          console.log("Does not match render cases.");
      }
    },
    [
      selectedWellOption,
      render,
      isLoaded,
      infoRender,
      wellEmissionSources,
      uwi,
      populatedUWI,
    ]
  );

  // Anything in the dependencies array of the renderInfo function also needs to be re-rendered using a useEffect to replace entire section.

  useEffect(() => {
    setWellEmissionSection(renderInfo("wellEmission"));
  }, [render, renderInfo, wellEmissionSources]);
  useEffect(() => {
    setReservoirSection(renderInfo("reservoir"));
  }, [renderInfo, populatedUWI, selectedWellOption]);

  useEffect(() => {
    setInformationSection(renderInfo("information"));
  }, [renderInfo, infoRender]);
  useEffect(() => {
    setWellSection(renderInfo("well"));
  }, [renderInfo, isLoaded]);

  const [informationSection, setInformationSection] = useState(
    renderInfo("information")
  );
  const [wellSection, setWellSection] = useState(renderInfo("well"));
  const [reservoirSection, setReservoirSection] = useState(
    renderInfo("reservoir")
  );
  const [fuelConsumptionSection, setFuelConsumptionSection] = useState(
    renderInfo("fuelConsumption")
  );
  const [flareVentSection, setFlareVentSection] = useState(
    renderInfo("flareVent")
  );
  const [emissionSourceSection, setEmissionSourceSection] = useState(
    renderInfo("emission")
  );
  const [fugitiveEmissionSection, setFugitiveEmissionSection] = useState(
    renderInfo("fugitiveEmissions")
  );
  const [wellEmissionSection, setWellEmissionSection] = useState(
    renderInfo("wellEmission")
  );

  // Requirement Alert Hooks
  const [informationAlertOpen, setInformationAlertOpen] = useState(false);
  const [informationRequiredMessage, setInformationRequiredMessage] =
    useState();

  const [reservoirAlertOpen, setReservoirAlertOpen] = useState(false);
  const [reservoirRequiredMessage, setReservoirRequiredMessage] = useState();

  //Closes Requirement alerts
  const handleReservoirAlertClose = () => {
    setReservoirAlertOpen(false);
  };
  const handleInformationAlertClose = () => {
    setInformationAlertOpen(false);
  };

  return {
    pdfEmail,
    information,
    checkRequired,
    renderMainAccordian: (
      <div>
        {informationSection}
        {wellSection}
        {reservoirSection}
        {flareVentSection}
        {fuelConsumptionSection}
        {emissionSourceSection}
        {wellEmissionSection}
        {fugitiveEmissionSection}

        <div className="mainButtonContainer">
          <Button
            onClick={() => window.location.reload()}
            className="calculateButton"
          >
            Reset
          </Button>
          <Button
            onClick={testDisplay}
            variant="contained"
            className="calculateButton"
          >
            Calculate
          </Button>
        </div>

        <div className="">
          {informationAlertOpen ? (
            <RequirementAlert
              open={informationAlertOpen}
              requirements={informationRequiredMessage}
              handleClose={handleInformationAlertClose}
            />
          ) : null}
          {reservoirAlertOpen ? (
            <RequirementAlert
              open={reservoirAlertOpen}
              requirements={reservoirRequiredMessage}
              handleClose={handleReservoirAlertClose}
            />
          ) : null}
        </div>

        <Grid sx={styles.gridContainer} container spacing={4}>
          <Grid item md={12}>
            <Grid style={styles.inputContainer} container spacing={1}>
              <Grid item lg={3} />
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <InputLabel sx={styles.labels} className="resultLabel">
                  Yearly tCO2e
                </InputLabel>
                <TextField
                  sx={styles.inputFields}
                  id="yearlytCO2eResult"
                  variant="outlined"
                  value={result1}
                />
              </Grid>
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <InputLabel sx={styles.labels} className="resultLabel">
                  tCO2e Remaining
                </InputLabel>
                <TextField
                  sx={styles.inputFields}
                  className="resultBox"
                  id="tCO2eResult"
                  variant="outlined"
                  value={result2}
                />
              </Grid>
              <Grid item lg={3} />
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <InputLabel
              sx={styles.carbonIntensityLabel}
              className="resultLabel"
            >
              Carbon Intensity (tCO2e/BOE)
            </InputLabel>
            <TextField
              sx={styles.carbonIntensityInputField}
              className="resultBox"
              id="carbonIntensityResult"
              variant="outlined"
              value={result3}
            />
          </Grid>
        </Grid>
      </div>
    ),
  };
}
const styles = {
  gridContainer: {
    display: "flex",
    alignItems: "center",
  },
  carbonIntensityLabel: {
    color: "black",
    fontSize: "23px",
    fontWeight: "600",
  },
  inputContainer: {},
  carbonIntensityInputField: {
    width: "40vh",
  },
  labels: {
    color: "black",
    fontSize: "20px",
    fontWeight: "500",
    // flexBasis: "50%",
  },

  inputFields: {
    width: "200px",
    maxWidth: "200px",
  },

  button: {},
};
