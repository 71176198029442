import React from 'react'


//Components 
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import HeaderandInputs from './HeaderandInputs';
import PopoverComponent from './PopoverComponent';

export default function InternalTabComponent({ accordianTitle, labels, inputs, valueChange, headers, gridSize, infoText }) {


    return (

        <Accordion className='accordianWrapper'>
            <AccordionSummary className='accordianSummary myAccordionSummaryClass'
                expandIcon={<ExpandCircleDownOutlinedIcon />}>
                <div className='tabSummary'>
                    <Typography variant='h6'>
                        {accordianTitle}
                    </Typography>
                    {infoText ? <div><PopoverComponent infoText={infoText} /> </div> : null}
                </div>

            </AccordionSummary>
            <AccordionDetails>
                {labels.map(function (label, index) {
                    return (
                        <HeaderandInputs
                            key={label[0] + index}
                            header={headers ? headers[index] : null}
                            labels={label}
                            inputs={inputs[index]}
                            valueChange={valueChange[index]}
                            gridSize={gridSize}
                        />
                    )
                })}

            </AccordionDetails>
        </Accordion>


    )
}
