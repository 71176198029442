import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./landingPage.css";
import { Box, IconButton } from "@mui/material";
import { revolvingTexts } from "./landingPageTexts";
import AuthContext from "../context/AuthContext";
export default function LandingPage() {
  const navigate = useNavigate();
  const { getLoggedIn, loggedIn } = useContext(AuthContext);
  // Redirect to home page if logged in
  useEffect(() => {
    if (loggedIn) {
      navigate("/home");
    }
  });

  // Reference to the typewriter element
  const typewriterRef = useRef(null);

  useEffect(() => {
    // Accessing the typewriter element
    const typewriterElement = typewriterRef.current;

    // Index of the current text in the revolvingTexts array
    let currentTextIndex = 0;

    // Index of the current word being typed or deleted
    let currentIndex = 0;

    // Flag indicating if the typewriter is currently in the deleting mode
    let isDeleting = false;

    // Function to handle typing and deleting effect
    const typeAndDelete = () => {
      const currentText = revolvingTexts[currentTextIndex];

      // Split the current text into words
      const words = currentText.split(" ");

      if (!isDeleting) {
        // Typing mode
        if (currentIndex >= words.length) {
          // If all words have been typed, switch to deleting mode
          isDeleting = true;
          setTimeout(typeAndDelete, 2000); // Delay before deleting starts
        } else {
          // If there are still words to be typed, increment the currentIndex and continue typing
          currentIndex++;
          typewriterElement.textContent = words
            .slice(0, currentIndex)
            .join(" ");
          typewriterElement.style.opacity = 1;
          setTimeout(typeAndDelete, 150); // Delay between typing each word
        }
      } else {
        // Deleting mode
        if (currentIndex === 0) {
          // If all words have been deleted, switch to typing mode for the next text
          isDeleting = false;
          currentIndex = 0;
          currentTextIndex = (currentTextIndex + 1) % revolvingTexts.length; // Move to the next text in the array
          setTimeout(typeAndDelete, 500); // Delay before typing starts
        } else {
          // If there are still words to be deleted, decrement the currentIndex and continue deleting
          currentIndex--;
          typewriterElement.textContent = words
            .slice(0, currentIndex)
            .join(" ");
          typewriterElement.style.opacity = 1;
          setTimeout(typeAndDelete, 50); // Delay between deleting each word
        }
      }
    };

    // Start the typewriter effect when the component mounts
    if (typewriterElement) {
      typeAndDelete();
    }
  }, []);

  return (
    <div className="landing-wrapper">
      <header className="landingHeader">
        <Box
          className="landingLogo"
          component="img"
          sx={{
            p: "1em",
            width: 250,
            maxWidth: { xs: 150, sm: 150, md: 250 },
          }}
          alt="Crbon Labs Logo."
          src="/images/CrbonLogoSmall.png"
        />
        <span className="landingLogin" onClick={() => navigate("/login")}>
          Login
        </span>
      </header>
      <main className="main">
        <div className="landing-main-text">
          <span className="crbonScope-purple-bold">CrbonScope. </span>
          <span className="text-grey-bold">
            <span className="typewriter-text" ref={typewriterRef}></span>
          </span>
        </div>
        <div>
          <p className="introducing-text-grey">
            Introducing Crbon Scope, a new way of looking at existing oil and
            gas facilities as assets in the energy transition.
          </p>
          <div className="button-container">
            <button
              className="btn-gradient-filled"
              onClick={() => navigate("/login")}
            >
              Get Started
            </button>
          </div>
        </div>
      </main>

      <footer className="landing-footer">
        <span>© 2023 Crbon Labs Inc. All Rights Reserved.</span>
        <div>
          <span className="footer-space link-pointer">Terms</span>
          <span className="link-pointer">Policy</span>
        </div>
      </footer>
    </div>
  );
}
