import React, { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Styles
import "../AccordianStyles.css";

// Components
import HeaderandInputsForMap from "../InputComponents.js/HeaderandInputsForMap";
import PopoverComponent from "../InputComponents.js/PopoverComponent";

// Units
import { LengthUnits } from "../../Utilities/units";

export default function BasicWellInfoTab(setPopulatedUWI, populatedUWI) {
  // An Array of array objets that may or may not contain these items inside
  // Item 1 is used as the id and key of the input element. (MANDATORY) (IMPORTANT: id must match EXACTLY to the hook object name)
  // Item 2 is used as the Label of the input element. (MANDATORY)
  // Item 3 is the list of units that the input may use. (OPTIONAL)
  // (IMPORTANT: Must include empty array (Ex. []) if item 4 is included)
  // Item 4 is the information text that is used for the on hover info icon. (OPTIONAL)
  const facilityLabels = [
    ["uwiSurface", "UWI Surface"],
    [
      "siteDescription",
      "Well Site Description",
      [],
      "Enter a short description of the well where the production originates.",
    ],
    [
      "facilityID",
      "Delivery Facility ID/UWI",
      [],
      "Enter the surface location of the facility where production from your well is delivered.",
    ],
    [
      "pipelineLength",
      "Pipeline's Length",
      { default: LengthUnits.units[1], units: LengthUnits.units },
      "If the well delivers to a pipeline, enter the length and select your units.",
    ],
  ];

  const [facility, setFacility] = useState({
    facility: {
      uwiSurface: { value: "", unit: "" },
      siteDescription: { value: "", unit: "" },
      facilityID: { value: "", unit: "" },
      pipelineLength: { value: "", unit: LengthUnits.units[1] },
    },
  });

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  // Populate Fields from populatedUWI object
  useEffect(() => {
    if (populatedUWI) {
      const facilityObj = populatedUWI.facility;

      let thisPipeLineLength;
      if (facilityObj.piplineLength || facilityObj.piplineLength === 0) {
        thisPipeLineLength = facilityObj.piplineLength.toString();
      }

      setFacility({
        facility: {
          uwiSurface: { value: populatedUWI.uwi, unit: "" },
          siteDescription: { value: facilityObj.siteDescription, unit: "" },
          facilityID: { value: facilityObj.facilityId, unit: "" },
          pipelineLength: {
            value: thisPipeLineLength,
            unit: facilityObj.piplineLengthUnit,
          },
        },
      });

      const wellLocationObj = populatedUWI.wellLocation;
      setLat(wellLocationObj.lat);
      setLng(wellLocationObj.lng);
    }
  }, [populatedUWI]);

  // holds the current UWI from the dropdown
  const [thisUWI, setThisUWI] = useState();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const wellLocation = {
    uwi: thisUWI,
    lat: lat,
    lng: lng,
  };

  return {
    wellLocation,
    isLoaded,
    facility,
    renderBasicWellInformation: (
      <div className="mainAccordian">
        <Accordion className="accordianWrapper">
          <AccordionSummary
            className="accordianSummary myAccordionSummaryClass"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="tabSummary">
              <Typography variant="h6">Basic Well Info</Typography>
              <div>
                <PopoverComponent
                  infoText={"Enter or Select a well location."}
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="allInputs">
              <Grid container spacing={1}>
                <Grid item lg={9} md={10} sm={12} xs={12}>
                  <HeaderandInputsForMap
                    header="Single/Multi-Well/Facility ID"
                    labels={facilityLabels}
                    inputs={facility}
                    inputTypes={["text", "text", "text", "number"]}
                    gridSize={12}
                    valueChange={setFacility}
                    uwiValueChange={setThisUWI}
                    isLoaded={isLoaded}
                    populatedUWISetter={setPopulatedUWI}
                    latSetter={setLat}
                    lngSetter={setLng}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    ),
  };
}
