const UNITS = {
//Oil Volumes
OilVolume: { default: "m3", units: ["m3", "gal", "BBL", "L"] },
OilVolumePerHour: { default: "m3/hr", units: ["m3/hr", "gal/hr", "BBL/hr", "L/hr"] },
OilDailyVolume: { default: "m3/day", units: ["m3/day", "gal/day", "BBL/day", "L/day"] },
OilMonthlyVolume: { default: "m3/month", units: ["m3/month", "gal/month", "BBL/month", "L/month"] },

//Gas Volumes
GasVolume: { default: "MCF", units: ["BOE", "MCF", "MMCF"] },
GasVolumePerHour: { default: "MCF/hr", units: ["BOE/hr", "MCF/hr", "MMCF/hr"] },
GasDailyVolume: { default: "MCF/day", units: ["BOE/day", "MCF/day", "MMCF/day"] },
GasMonthlyVolume: { default: "MCF/month", units: ["BOE/month", "MCF/month", "MMCF/month"] },    

// Case by Case volume units
Volume: { default: "m3", units: ["m3", "gal", "BBL", "L", "BOE", "MCF", "MMCF"] },
VolumePerHour: { default: "m3/hr", units: ["m3/hr", "gal/hr", "BBL/hr", "L/hr"] },
DailyVolume: { default: "m3/day", units: ["m3/day", "gal/day", "BBL/day", "L/day", "BOE/day", "MCF/day"] },
MonthlyVolume: { default: "MCF/month", units: ["BOE/month", "MCF/month"] },



    Pressure: { default: "kPa", units: ["kPa", "atm", "mmHg", "psi", "torr"] },
    Temperature: {
        default: "K", units: ["K", "C", "F"],
    },
    Length: {
        default: "m", units: ["m", "km", "cm", "feet", "inches"]
    },
    Select: {
        default: "High", units: ["High", "Low"]
    },
    FlareGasType: {
        default: "Sales Gas", units: ["Sales Gas", "Lean Gas", "Medium-rich gas", "Rich gas", "Rich gas with HHV > 50 MJ/m3", "Still Gas (Upgrading)", "Still Gas (Refinery and Others)", "100% Methane", "100% Ethane (C2)", "100% Propane (C3)", "100% Butane (C4)", "Landfill Gas (%50 methane)"]
    }

}

const PressureUnits = UNITS.Pressure;

const OilVolumeUnits = UNITS.OilVolume;
const OilVolumePerHourUnits = UNITS.OilVolumePerHour;
const OilDailyVolumeUnits = UNITS.OilDailyVolume;
const OilMonthlyVolumeUnits = UNITS.OilMonthlyVolume;

const GasVolumeUnits = UNITS.GasVolume;
const GasVolumePerHourUnits = UNITS.GasVolumePerHour;
const GasDailyVolumeUnits = UNITS.GasDailyVolume;
const GasMonthlyVolumeUnits = UNITS.GasMonthlyVolume;

const VolumeUnits = UNITS.Volume;
const VolumePerHourUnits = UNITS.VolumePerHour;
const DailyVolumeUnits = UNITS.DailyVolume;
const MonthlyVolumeUnits = UNITS.MonthlyVolume;
const TemperatureUnits = UNITS.Temperature;
const LengthUnits = UNITS.Length;
const SelectUnits = UNITS.Select;
const FlareGasUnits = UNITS.FlareGasType;

export {
    GasVolumeUnits,
    GasVolumePerHourUnits,
    GasDailyVolumeUnits,
    GasMonthlyVolumeUnits,
    OilVolumeUnits,
    OilVolumePerHourUnits,
    OilDailyVolumeUnits,
    OilMonthlyVolumeUnits,
    PressureUnits, 
    VolumeUnits, 
    VolumePerHourUnits, 
    DailyVolumeUnits, 
    MonthlyVolumeUnits, 
    TemperatureUnits, 
    LengthUnits, 
    SelectUnits, 
    FlareGasUnits }