import React, { createContext, useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
const AuthContext = createContext();

function AuthContextProvider(props) {
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [loading, setLoading] = useState(true); // Add loading state

  async function getLoggedIn() {
    const loggedInRes = await axios.get("https://api.crbon.ca/auth/loggedIn");
    setLoggedIn(loggedInRes.data);
    setLoading(false); // Set loading to false once the response is received
  }

  useEffect(() => {
    getLoggedIn();
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ marginBottom: "2vh" }}>
            Hang tight! We're just getting things fired up for you.
          </span>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    );
  }

  return (
    <AuthContext.Provider value={{ loggedIn, getLoggedIn }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
