import React from 'react'
import "./FooterStyles.css";

import { Box, Typography } from "@mui/material"
export default function Footer() {
  return (
    <div className="footer">
      <div className='logoLabelWrapper'>
        <Typography className="bwLogoLabel" variant="h6">
          Made in collaboration with
        </Typography>
      </div>

      <div className='bwLogoContainer'>
        <Box
          component="img"
          sx={{
            p: "0",
            width: 400,
            maxWidth: { xs: 250, md: 250 },
            maxHeight: { xs: 150, md: 250 }
          }}
          src="/images/BreakwaterVantageLogo3.png"
        >
        </Box>

      </div>
      <div className='copyrightText'>
        <Typography variant='caption'>© 2022 Crbon Labs Inc. All Rights Reserved.</Typography>
      </div>


    </div>
  )
}
