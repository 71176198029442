import axios from "axios";

/**
 *
 * @param {Object} inputs - The nested object that contains all values for the calculation.
 * @param {String} email - The confirmed email of the user where the PDF will be sent.
 * @param {Function} callback - A callback function that handles the response.
 */

const loginUser = function (password, callback) {
  axios
    .post(
      "https://api.crbon.ca/auth/login",
      {
        password: password,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err.response.data));
};

const saveSendPdfEmail = function (inputs, email, callback) {
  axios
    .post(
      "https://crbonlabscalculator-api.onrender.com/calculator/save-generate-pdf",
      {
        inputs,
        email,
      }
    )
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("saveSendPdfEmail error"));
};

/**
 *
 * @param {Object} inputs - The nested object that contains all values for the calculation.
 * @param {Function} callback - A callback function that handles the response.
 */
const sendInputs = function (inputs, callback) {
  axios
    .post("https://crbonlabscalculator-api.onrender.com/calculator/", {
      inputs,
    })
    .then((res) => {
      console.log(res.data);
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

/**
 *
 * @param {String} years - value for calculation
 * @param {String} oilRate - value for calculation
 * @param {String} oilRateUnit - value for calculation
 * @param {String} oilWellGasRate - value for calculation
 * @param {String} oilWellGasRateUnit - value for calculation
 * @param {String} gasRate - value for- value for calculation calculation
 * @param {String} gasRate - value for- value for calculation calculation
 * @param {String} gasRateUnit - value for- value for calculation calculation
 * @param {*} callback - A callback function that handles the response.
 */
const calculatedReserves = function (
  years,
  oilRate,
  oilRateUnit,
  oilWellGasRate,
  oilWellGasRateUnit,
  gasRate,
  gasRateUnit,
  callback
) {
  axios
    .post(
      "https://crbonlabscalculator-api.onrender.com/calculator/calculatedReserves",
      {
        years,
        oilRate,
        oilRateUnit,
        oilWellGasRate,
        oilWellGasRateUnit,
        gasRate,
        gasRateUnit,
      }
    )
    .then((res) => {
      console.log(res.data);
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

/**
 *
 * @param {String} oilRate - value for calculation
 * @param {String} oilRateUnit - value for calculation
 * @param {String} oilWellGasRate - value for calculation
 * @param {String} oilWellGasRateUnit - value for calculation
 * @param {*} callback - A callback function that handles the response.
 */
const calculatedGor = function (
  oilRate,
  oilRateUnit,
  oilWellGasRate,
  oilWellGasRateUnit,
  callback
) {
  axios
    .post(
      "https://crbonlabscalculator-api.onrender.com/calculator/calculatedGor",
      {
        oilRate,
        oilRateUnit,
        oilWellGasRate,
        oilWellGasRateUnit,
      }
    )
    .then((res) => {
      console.log(res.data);
      callback(res.data);
    })
    .catch((err) =>
      console.log("Something went wrong with send inputs to database")
    );
};

/**
 *
 * @param {String} uwi - UWI that dictates which long and lat to use for Google map.
 * @param {Function} callback - A callback function that handles the response.
 */
const grabCoordinates = function (uwi, callback) {
  axios
    .post(
      "https://crbonlabscalculator-api.onrender.com/calculator/getCoordinates",
      {
        uwi,
      }
    )
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("Error: " + err));
};

/**
 *
 * @param {String} value - token generated when user clicks captcha.
 * @param {Function} callback - A callback function that handles the response.
 */
const captchaCheck = function (value, callback) {
  axios
    .post(
      "https://crbonlabscalculator-api.onrender.com/calculator/captchaCheck",
      {
        token: value,
      }
    )
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("Error: " + err));
};

/**
 *
 * @param {String} uwi - The uwi entry that we want.
 * @param {Function} callback - A callback function that handles the response.
 */
const getEntry = function (uwi, callback) {
  axios
    .post("https://crbonlabscalculator-api.onrender.com/calculator/getEntry", {
      uwi,
    })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("Error: " + err));
};

/**
 *
 * @param {Function} callback - A callback function that handles the response.
 */
const getUWIs = function (callback) {
  axios
    .get("https://crbonlabscalculator-api.onrender.com/calculator/getUWIs")
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => console.log("Error: " + err));
};

export {
  loginUser,
  sendInputs,
  saveSendPdfEmail,
  grabCoordinates,
  captchaCheck,
  calculatedReserves,
  calculatedGor,
  getEntry,
  getUWIs,
};
