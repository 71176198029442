import React, { useContext, useEffect, useState } from "react";
import validator from "validator";
import { Card, TextField, Box } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../APIs/databaseAPI";
import AuthContext from "../context/AuthContext";

import "./loginStyles.css";
export default function LoginPage() {
  const navigate = useNavigate();
  const { getLoggedIn, loggedIn } = useContext(AuthContext);
  // State Variables
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState({
    error: false,
    text: "",
  });

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setPassword(newValue);
  };

  // Redirect to home page if logged in
  useEffect(() => {
    if (loggedIn) {
      navigate("/home");
    }
  });

  // Triggers onClick for Sign in button
  const onSubmit = () => {
    loginUser(validator.escape(password), function (response) {
      if (response.status === 200) {
        if (getLoggedIn()) {
          navigate("/home");
        }
      } else {
        setErrorText({
          error: true,
          text: "Invalid password.",
        });
      }
    });
  };
  return (
    <div className="login-wrapper">
      <div className="logoContainer">
        <Box
          component="img"
          sx={{
            width: 400,
            maxWidth: { xs: 250, md: 400 },
            maxHeight: { xs: 250, md: 400 },
            cursor: "pointer",
          }}
          alt="PTAC Logo."
          src="/images/CrbonLogoSmall.png"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="loginContainer">
        <Card className="loginCard flex-column" elevation={2}>
          <h1 className="loginTitle">Login Now</h1>

          <div className="flex-column">
            <TextField
              className="passwordInput"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              variant="outlined"
            />
            {errorText.error && (
              <span className="error-text">{errorText.text}</span>
            )}

            <div className="buttonContainer">
              <button
                className="loginButton"
                onClick={onSubmit}
                variant="contained"
              >
                Sign in
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
